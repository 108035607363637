import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import * as XLSX from "xlsx";
import { useAudioProfile } from "../contexts/AudioProfileContext";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#white", // Black
    color: "#FFD700", // Gold
    boxShadow: "0px 4px 20px 0px rgba(0,0,0,0.15)", // Shadow
    padding: "20px", // Padding
    borderRadius: "15px", // Rounded corners
    margin: "20px 0", // Margin top and bottom
    width: "98%", // Full width
  },
  title: {
    color: "#FFFFFF", // White
  },
  button: {
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "#f7f7f7",
    },
    margin: theme.spacing(2),
    fontSize: "1rem", // Increase font size
  },
  textField: {
    "& input": {
      fontSize: "1.15rem", // Increase font size
      textAlign: "center", // Center text
    },
  },
  tableCell: {
    // Define styles for the TableCell
    fontSize: "1.15rem", // Increase font size
    textAlign: "center", // Center text horizontally
  },
  tooltipContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    marginBottom: "10px",
  },
  tooltipText: {
    marginRight: theme.spacing(1), // Adjust the spacing as needed
    color: "black",
    fontSize: "1.15rem",
  },
  customTooltip: {
    fontSize: "1.15rem", // Adjust the font size as needed
  },
}));

const InfoTooltip = ({ info }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={<Typography className={classes.customTooltip}>{info}</Typography>}
      placement="top"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 0], // Adjust the position of the tooltip as needed
            },
          },
        ],
      }}
      interactive
    >
      <IconButton>
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );
};

const TableComponent = ({ onSubmit }) => {
  const { tableData, saveTableData } = useAudioProfile();
  const [localTableData, setLocalTableData] = useState(
    tableData && tableData.length > 0
      ? tableData
      : [
          {
            id: "",
            age: "",
            ear: "",
            "125 dB": "",
            "250 dB": "",
            "500 dB": "",
            "1000 dB": "",
            "1500 dB": "",
            "2000 dB": "",
            "3000 dB": "",
            "4000 dB": "",
            "6000 dB": "",
            "8000 dB": "",
          },
        ],
  );
  const [selected, setSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [duplicateIds, setDuplicateIds] = useState([]);
  const [error, setError] = useState("");
  const [openIdConfirmationDialog, setOpenIdConfirmationDialog] =
    useState(false);
  const [idsWithMultipleEntries, setIdsWithMultipleEntries] = useState([]);
  const classes = useStyles();

  const addRow = () => {
    setLocalTableData([
      ...(Array.isArray(localTableData) ? localTableData : []),
      {
        id: "",
        age: "",
        ear: "",
        "125 dB": "",
        "250 dB": "",
        "500 dB": "",
        "1000 dB": "",
        "1500 dB": "",
        "2000 dB": "",
        "3000 dB": "",
        "4000 dB": "",
        "6000 dB": "",
        "8000 dB": "",
      },
    ]);
  };

  const deleteRows = async () => {
    setLocalTableData(
      localTableData.filter((_, index) => !selected.includes(index)),
    );
    setSelected([]);
    // check if localTableData is empty after and if so add a new row
    if (localTableData.length === 0) {
      addRow();
    }
  };

  const selectAll = () => {
    setSelected(localTableData.map((_, index) => index));
  };

  const handleFileUpload = (event) => {
    setLocalTableData([
      {
        id: "",
        age: "",
        ear: "",
        "125 dB": "",
        "250 dB": "",
        "500 dB": "",
        "1000 dB": "",
        "1500 dB": "",
        "2000 dB": "",
        "3000 dB": "",
        "4000 dB": "",
        "6000 dB": "",
        "8000 dB": "",
      },
    ]);
    const file = event.target.files[0];
    console.log("File uploaded");
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets["Audio"];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        range: 2,
      }); // Get rows as arrays of cells starting from the 3rd row

      // Filter out rows where the first cell (id) is empty
      const validRows = jsonData.filter((row) => row[0]);

      const formattedData = validRows.map((row) => ({
        id: row[0], // First cell in the row is the id
        age: row[1], // Second cell in the row is age
        ear: row[2], // Third cell in the row is the ear
        // Assuming the next cells are the frequencies in order
        "125 dB": row[3] || "",
        "250 dB": row[4] || "",
        "500 dB": row[5] || "",
        "1000 dB": row[6] || "",
        "1500 dB": row[7] || "",
        "2000 dB": row[8] || "",
        "3000 dB": row[9] || "",
        "4000 dB": row[10] || "",
        "6000 dB": row[11] || "",
        "8000 dB": row[12] || "",
        // Add more frequencies if needed
      }));
      console.log("formattedData: ", formattedData);
      setLocalTableData(formattedData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement("a");
    link.href = require("../Assets/template1.xls"); // Assuming create-react-app's asset management
    link.download = "template1.xls";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSampleData = () => {
    const sampleData = [
      {
        id: "ID 12",
        age: "49.906",
        ear: "R",
        "125 dB": "45",
        "250 dB": "40",
        "500 dB": "40",
        "1000 dB": "45",
        "1500 dB": "55",
        "2000 dB": "55",
        "3000 dB": "60",
        "4000 dB": "70",
        "6000 dB": "60",
        "8000 dB": "70",
      },
      {
        id: "ID 13",
        age: "26.015",
        ear: "R",
        "125 dB": "60",
        "250 dB": "65",
        "500 dB": "70",
        "1000 dB": "65",
        "1500 dB": "65",
        "2000 dB": "65",
        "3000 dB": "60",
        "4000 dB": "55",
        "6000 dB": "35",
        "8000 dB": "20",
      },
      {
        id: "ID 13",
        age: "27.608",
        ear: "R",
        "125 dB": "60",
        "250 dB": "65",
        "500 dB": "70",
        "1000 dB": "65",
        "1500 dB": "65",
        "2000 dB": "65",
        "3000 dB": "55",
        "4000 dB": "40",
        "6000 dB": "15",
        "8000 dB": "15",
      },
    ];
    setLocalTableData(sampleData);
  };

  // Assuming localTableData is your state variable holding the table rows
  const modelMap = new Map();

  localTableData.forEach((row) => {
    const count = modelMap.get(row.id) || 0;
    modelMap.set(row.id, count + 1);
  });

  const getModelForId = (id) => {
    const count = modelMap.get(id);
    return count > 1 ? "AudioGene V4" : "AudioGene V9.1";
  };

  const handleInputChange = (event, index) => {
    let { name, value } = event.target;

    // Truncate to two decimal places if it's a dB field and contains a decimal point
    if (name.includes("dB") && value.includes(".")) {
      value = value.match(/^\d+\.\d{0,2}/)
        ? value.match(/^\d+\.\d{0,2}/)[0]
        : value;
    }

    let isValid = true;
    if (name === "ear") {
      // Temporarily relax validation to allow progressive typing of "Better", "R", "L", or an empty string
      isValid = [
        "",
        "B",
        "Be",
        "Bet",
        "Bett",
        "Bette",
        "Better",
        "R",
        "L",
      ].includes(value);
    } else if (name === "id") {
      // Allow progressive typing: "I", "ID", "ID " and then a number greater than 0
      if (value.startsWith("ID") && value.length > 3) {
        const numberPart = value.substring(3); // Get the part after "ID "
        isValid = /^\d+$/.test(numberPart) && parseInt(numberPart, 10) > -1;
      } else {
        // Temporarily relax validation to allow progressive typing of "ID" and "ID "
        isValid = ["", "I", "ID", "ID "].includes(value);
      }

      if (!isValid) {
        // Optionally handle the invalid input case, e.g., by showing an error message
        console.error(
          "ID must be in the format 'ID number' where number is an integer greater than 0.",
        );
        // Prevent further processing or updating the state with the invalid value
        return;
      }
    } else {
      // For all other fields, allow only digits between -5 to 150 or an empty string
      const numValue = parseInt(value, 10);
      isValid =
        value === "" ||
        (!isNaN(numValue) &&
          numValue >= -5 &&
          numValue <= 150 &&
          /^-?\d+$/.test(value));
    }

    if (isValid) {
      const newTableData = [...localTableData];
      newTableData[index][name] = value;
      setLocalTableData(newTableData);
    } else {
      // Optionally handle the invalid input case, e.g., by showing an error message
    }
  };

  const handleCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setSelected([...selected, index]);
    } else {
      setSelected(selected.filter((i) => i !== index));
    }
  };

  const handleSubmit = () => {
    // Reset any previous errors or states
    setError("");
    setOpenIdConfirmationDialog(false);
    setIdsWithMultipleEntries([]);

    const mandatoryFieldsFilled = localTableData.every(
      (row) => row.id && row.age && row.ear,
    );
    const minimumFrequencyValues = localTableData.every((row) => {
      const frequencies = [
        "125 dB",
        "250 dB",
        "500 dB",
        "1000 dB",
        "1500 dB",
        "2000 dB",
        "3000 dB",
        "4000 dB",
        "6000 dB",
        "8000 dB",
      ];
      let filledFrequencies = 0;
      frequencies.forEach((frequency) => {
        if (row[frequency]) filledFrequencies++;
      });
      return filledFrequencies >= 3;
    });

    if (!mandatoryFieldsFilled) {
      setError("Error: ID, Age, and Ear fields must not be blank.");
      return;
    }

    if (!minimumFrequencyValues) {
      setError("Error: At least 3 frequencies must have a value.");
      return;
    }

    // Create a structure to track the occurrences of ID combinations
    const idCombinations = {};

    // Iterate over the table data to populate the idCombinations structure
    localTableData.forEach(({ id, age, ear }) => {
      const key = `${id}-${age}-${ear}`;
      const idKey = `${id}`;

      // Track occurrences of each specific combination of ID, age, and ear
      if (idCombinations[key]) {
        idCombinations[key].count++;
      } else {
        idCombinations[key] = { count: 1, ear, age };
      }

      // Also, track occurrences of each ID regardless of age and ear for the first rule
      if (idCombinations[idKey]) {
        idCombinations[idKey].total++;
      } else {
        idCombinations[idKey] = { total: 1 };
      }
    });

    // Check for violations of the specified rules
    for (const [key, { count, total, ear, age }] of Object.entries(
      idCombinations,
    )) {
      // Rule 2: Entries with the same ID and age cannot have the same ear
      if (count > 1) {
        setError(
          `Error: Duplicate entry found for the same patient with ID ${key.split("-")[0]}, Age: ${age}, and Ear: ${ear}.`,
        );
        return;
      }
    }

    let openIdConfirmationDialogLocal = false;
    for (const [key, { count, total, ear, age }] of Object.entries(
      idCombinations,
    )) {
      console.log("key: ", key);
      console.log("count: ", count);
      if (total > 3) {
        if (!duplicateIds.includes(key.split("-")[0])) {
          setDuplicateIds([...duplicateIds, key.split("-")[0]]);
        }
        console.log("duplicateIds: ", duplicateIds);
        openIdConfirmationDialogLocal = true;
      }
    }
    if (openIdConfirmationDialogLocal) {
      setOpenDialog(true);
      return;
    }

    // If no errors, proceed with further validations or submission
    proceedWithSubmission();
  };

  const proceedWithSubmission = () => {
    // Update the parent state when the user submits the form
    saveTableData(localTableData);
  };

  const handleCloseDialog = (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      // If the user confirmed, proceed with the submission
      proceedWithSubmission();
    }
  };

  useEffect(() => {
    if (localTableData) {
      setLocalTableData(localTableData);
    }
  }, [localTableData, setLocalTableData]);

  return (
    <Card className={classes.card}>
      <CardHeader title="Input Audiogram Data" className={classes.title} />
      <div className={classes.tooltipContainer}>
        <Typography variant="body1" className={classes.tooltipText}>
          How to Enter Data
        </Typography>
        <InfoTooltip info="Enter the patient's ID as (ID `number`), age, and audiogram data for at least 3 frequencies. Use the 'Add Row' button for multiple entries." />
        <Typography variant="body1" className={classes.tooltipText}>
          How Models are Chosen
        </Typography>
        <InfoTooltip info="For patients with more than one audiogram, AudioGene V4 will be used. For a single audiogram, AudioGene V9.1 will be applied." />
      </div>
      <Button onClick={addRow} className={classes.button}>
        Add Row
      </Button>
      <Button onClick={deleteRows} className={classes.button}>
        Delete Selected
      </Button>
      <Button onClick={selectAll} className={classes.button}>
        Select All
      </Button>
      <Button onClick={handleDownloadTemplate} className={classes.button}>
        Download Template
      </Button>
      <Button onClick={handleSampleData} className={classes.button}>
        Load Sample Data
      </Button>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        id="raised-button-file"
      />
      <label htmlFor="raised-button-file">
        <Button variant="raised" component="span" className={classes.button}>
          Upload File
        </Button>
      </label>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Select</TableCell>
              <TableCell className={classes.tableCell}>ID</TableCell>
              <TableCell className={classes.tableCell}>Age (Years)</TableCell>
              <TableCell className={classes.tableCell}>
                Ear (L/R/Better)
              </TableCell>
              <TableCell className={classes.tableCell}>125 Hz</TableCell>
              <TableCell className={classes.tableCell}>250 Hz</TableCell>
              <TableCell className={classes.tableCell}>500 Hz</TableCell>
              <TableCell className={classes.tableCell}>1000 Hz</TableCell>
              <TableCell className={classes.tableCell}>1500 Hz</TableCell>
              <TableCell className={classes.tableCell}>2000 Hz</TableCell>
              <TableCell className={classes.tableCell}>3000 Hz</TableCell>
              <TableCell className={classes.tableCell}>4000 Hz</TableCell>
              <TableCell className={classes.tableCell}>6000 Hz</TableCell>
              <TableCell className={classes.tableCell}>8000 Hz</TableCell>
              <TableCell className={classes.tableCell}>Model</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localTableData &&
              localTableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(index)}
                      onChange={(event) => handleCheckboxChange(event, index)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="id"
                      value={row.id}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="age"
                      value={row.age}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="ear"
                      value={row.ear}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="125 dB"
                      value={row["125 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="250 dB"
                      value={row["250 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="500 dB"
                      value={row["500 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="1000 dB"
                      value={row["1000 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="1500 dB"
                      value={row["1500 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="2000 dB"
                      value={row["2000 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="3000 dB"
                      value={row["3000 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="4000 dB"
                      value={row["4000 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="6000 dB"
                      value={row["6000 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="8000 dB"
                      value={row["8000 dB"]}
                      onChange={(event) => handleInputChange(event, index)}
                      className={classes.textField}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {getModelForId(row.id)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button onClick={handleSubmit} className={classes.button}>
        Submit
      </Button>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Duplicate IDs Detected"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The following IDs are duplicated more than 3 times:{" "}
            {duplicateIds.join(", ")}. Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => handleCloseDialog(true)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {error && (
        <Snackbar
          open={Boolean(error)}
          autoHideDuration={6000}
          onClose={() => setError("")}
        >
          <Alert
            onClose={() => setError("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}
    </Card>
  );
};
export default TableComponent;
