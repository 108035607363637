import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Button,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import EthnicityGeneScatter from "./EthnicityGeneScatter";
import ThreeDClustering from "./ThreeDClustering";
import GeneExpressionOverAge from "./GeneExpressionOverAge";
import GeneCountChart from "./GeneCountChart";
import AudioProfiles from "./audioProfiles";
import TableComponent from "./tableComponent";
import SubmittedDataTable from "./SubmittedDataTable";
import { dimensions } from "../constants";
import ProcessAudiogramData from "./ProcessAudiogramData";
import { styled } from "@mui/system";
import { useAudioProfile } from "../contexts/AudioProfileContext";
import UserAuthorization from "../Utilities/user_auth";
import ErrorBoundary from "./ErrorBoundary"; // Adjust the import path as necessary
import SurfaceViewer from "./surfaceViewer";

const useStyles = makeStyles((theme) => ({
  bigCard: {
    boxShadow: "4px 4px 8px 4px rgba(0,0,0,0.2)",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    },
    width: "98%",
    height: dimensions.height,
    marginBottom: "20px",
  },
  mediumCard: {
    boxShadow: "4px 4px 8px 4px rgba(0,0,0,0.2)",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    },
    width: "98%",
    height: dimensions.height * 0.75,
    marginBottom: "20px",
  },
  smallCard: {
    boxShadow: "4px 4px 8px 4px rgba(0,0,0,0.2)",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    },
    width: "98%",
    height: dimensions.height * 0.65,
    marginBottom: "20px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  cardContentBig: {
    overflowY: "auto", // Enable vertical scrolling
    maxHeight: "100%", // Adjust height to ensure it fits within the parent container
  },
  contentMedium: {
    overflowY: "auto", // Enable vertical scrolling
    maxHeight: "100%", // Adjust height to ensure it fits within the parent container
  },
  cardContentSmall: {
    overflowY: "auto", // Enable vertical scrolling
    maxHeight: "100%", // Adjust height to ensure it fits within the parent container
  },
  gridTitle: {
    marginBottom: "20px",
  },
  selectGeneTitle: {
    marginRight: "20px",
  },
  container: {
    height: "100%",
    width: "100%", // Adjust width to ensure it fits within the parent container
    overflowX: "hidden", // Prevent horizontal scrolling
    display: "flex", // Using flexbox to control layout
    flexDirection: "column", // Stack children vertically
    justifyContent: "center", // Center children vertically
    alignItems: "center", // Center children horizontally
  },
  menuItem: {
    width: "50%",
    color: "black",
  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: "20%",
    maxWidth: "40%",
    height: dimensions.height * 0.08,
  },
  button: {
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    marginLeft: "20px",
  },
  select: {
    marginBottom: theme.spacing(2),
    width: "100%",
    height: "65%",
    fontStyle: "italic",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      color: "gold",
      backgroundColor: "white",
    },
  },
  "&.MuiMenuItem-selected": {
    backgroundColor: "black",
    color: "white",
  },
  width: "100%",
  fontStyle: "italic",
}));

const Visualization = ({ onNext, hypothesis, setHypothesis, allGenes }) => {
  const classes = useStyles();
  const {
    visualizationState,
    saveVisualizationState,
    isPredicted,
    predictionData,
    savePredictionData,
    clearVisualizationState,
    saveIsPredicted,
    clearPredictionData,
    tableData,
    saveProcessedTableData,
    clearProcessedTableData,
    clearAllState,
  } = useAudioProfile();
  const [visualizationData, setVisualizationData] = useState(
    hypothesis.allGeneData,
  );
  const [loading, setLoading] = useState(false);
  const [audiogramPredicted, setAudiogramPredicted] = useState(false);
  const [totalSamples, setTotalSamples] = useState(2000);
  const [classBalancingMethods, setClassBalancingMethods] = useState([
    "SMOTE",
    "NearMiss",
  ]);
  const [selectedGenes, setSelectedGenes] = useState(
    Array.isArray(hypothesis.selectedGenes) ? hypothesis.selectedGenes : [],
  );
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [dataFetched, setDataFetched] = useState(null);
  const [predictAudioProfiles, setPredictAudioProfiles] = useState(false);
  const [predictAudioProfilesCounter, setPredictAudioProfilesCounter] =
    useState(0);

  useEffect(() => {
    // Load state from context when component mounts
    if (visualizationState && Object.keys(visualizationState).length > 0) {
      // Set your component's state based on visualizationState
      setHypothesis(visualizationState.hypothesis);
      setSelectedGenes(visualizationState.selectedGenes);
      setAudiogramPredicted(visualizationState.audiogramPredicted);
      setVisualizationData(visualizationState.visualizationData);
      setDataFetched(true);
      setPredictAudioProfiles(true);
    } else {
      console.log("No visualization state to load");
      setDataFetched(false);
    }
  }, []); // Empty dependency array ensures this runs once on mount

  async function saveFetchData(data) {
    setVisualizationData(data);
    setHypothesis({
      ...hypothesis,
      resampling: data.resampling,
      allGeneData: data,
    });
    const newHyptothesis = {
      ...hypothesis,
      resampling: data.resampling,
      allGeneData: data,
    };
    await saveVisualizationState({
      hypothesis: newHyptothesis,
      selectedGenes: selectedGenes,
      visualizationData: data,
    });
    await savePredictionData(predictionData, () => {});

    await saveProcessedTableData(data.tableDataFromData, () => {});
  }

  async function fetchNewData() {
    if (
      dataFetched !== null &&
      tableData &&
      tableData.length > 0 &&
      !dataFetched &&
      isPredicted
    ) {
      // Reset state when new predictions are made
      // Fetch new data and update state accordingly
      const data = await fetchData();
      if (data) {
        // After fetching and updating state, save it:
        saveFetchData(data);
        setDataFetched(true);
      } else {
        console.error("No data fetched");
      }
    }
  }

  useEffect(() => {
    fetchNewData();
    // }, [/* dependencies including isPredicted and any state variables that, when changed, should trigger a save */]);
  }, [isPredicted, hypothesis, selectedGenes, audiogramPredicted, dataFetched]);

  async function fetchData(
    resample = false,
    minThreshold = 50,
    maxThreshold = 250,
  ) {
    try {
      if (tableData.length === 0 && dataFetched !== null) {
        console.error("No table data to fetch");
        return;
      }
      console.log("Min and max threshold", minThreshold, maxThreshold);
      const response = await axios.post("/data_visualization", {
        data: tableData,
        minThreshold: minThreshold,
        maxThreshold: maxThreshold,
        classBalancingMethod: classBalancingMethods,
        totalSamples: totalSamples,
        filteredGenes: hypothesis.selectedGenes,
        resample: resample,
      });
      if (!response.data) {
        console.error("No data returned from the API");
        return; // Exit if no data is returned
      }

      const jobId = response.data.jobId;
      const visualizationData = await fetchVisualizationData(jobId);
      console.log("Visualization data", visualizationData);
      saveFetchData(visualizationData);
      return visualizationData; // Ensure this is returning the expected data structure
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  }

  // useEffect(() => {
  //   const checkAudiogramPredictedAndFetchData = async () => {
  //     if (audiogramPredicted) {
  //       setLoading(true);
  //       if (tableData.length === 0) {
  //         setLoading(false);
  //         return;
  //       }
  //       await fetchData();
  //     } else {
  //       setLoading(false);
  //     }
  //   };

  //   checkAudiogramPredictedAndFetchData();
  // }, [audiogramPredicted, tableData]);

  const fetchVisualizationData = async (jobId) => {
    return new Promise(async (resolve, reject) => {
      const checkStatus = setInterval(async () => {
        try {
          const response = await axios.get(`/check_status/${jobId}`);
          if (response.data.status === "completed") {
            clearInterval(checkStatus);
            const processedData = processVisualizationData(
              response.data.result,
            );
            resolve(processedData); // Resolve the promise with the processed data
          } else if (response.data.status === "error") {
            if (response.data.status === 403) {
              // redirect to login page
              window.location.href = "/login";
              return;
            }

            clearInterval(checkStatus);
            console.error(
              "Error fetching visualization data:",
              response.data.error,
            );
            reject(new Error("Error fetching visualization data"));
          }
        } catch (error) {
          clearInterval(checkStatus);
          console.error("Error:", error);
          reject(error);
        }
      }, 3000); // Check status every 3 seconds
    });
  };

  const processVisualizationData = async (data) => {
    // Example of processing the provided data structure
    const processedData = {
      scatterData: data.scatterData.map((item) => ({
        gene: item.y,
        ethnicity: item.x,
      })),

      geneExpressionData: data.geneExpressionData.map((item) => ({
        age: item.age,
        gene: item.gene,
        geneEncoded: item.gene_encoded, // If additional processing is required, apply here
      })),

      clusteringData: {
        greedy: data.clusteringDataGreedy.map(mapClusteringData),
        original: data.clusteringDataOriginal.map(mapClusteringData),
      },

      geneCounts: data.geneCounts,
      geneCountsAfterResampling: data.geneCountsAfterResampling,
      tableDataCluster: data.table_df_data, // Process if necessary

      tableDataFromData: data.table_df.map((item) => ({
        id: item.id,
        age: item.age,
        "125 dB": item["125 Hz"],
        "250 dB": item["250 Hz"],
        "500 dB": item["500 Hz"],
        "1000 dB": item["1000 Hz"],
        "1500 dB": item["1500 Hz"],
        "2000 dB": item["2000 Hz"],
        "3000 dB": item["3000 Hz"],
        "4000 dB": item["4000 Hz"],
        "6000 dB": item["6000 Hz"],
        "8000 dB": item["8000 Hz"],
      })),

      audiogenev4_audiograms_median_table_df:
        data.audiogenev4_audiograms_median_table_df,

      filteredGenes: data.filteredGenes,
      processed_data: data.processed_data,
      resampling: data.resampling,
    };

    return processedData;
  };

  const handleGeneChange = (event) => {
    setSelectedGenes(event.target.value);
    const selectedGenesValues = event.target.value;
    setHypothesis({ ...hypothesis, selectedGenes: selectedGenesValues });
    const newHyptothesis = {
      ...hypothesis,
      selectedGenes: selectedGenesValues,
    };
    saveVisualizationState({
      hypothesis: newHyptothesis,
    });
    setShowUpdateButton(true);
  };

  // clearAllData();

  const clearAllData = () => {
    clearAllState();
    setHypothesis({
      ...hypothesis,
      audiogramPredicted: false,
      allGeneData: [],
    });
    setVisualizationData(null);
    setPredictAudioProfiles(false); // Trigger new prediction
    // refresh the page
    window.location.reload();
  };

  // clearVisualizationState(); // Clear saved state
  //   setDataFetched(false);
  //   setHypothesis({ ...hypothesis, audiogramPredicted: false, allGeneData: [] });
  //   setVisualizationData(null);
  //   setPredictAudioProfiles(true); // Trigger new prediction
  //   setPredictAudioProfilesCounter(predictAudioProfilesCounter + 1);
  //   saveIsPredicted(false);
  //   clearPredictionData();

  const handlePredictClick = () => {
    clearVisualizationState(); // Clear saved state
    setDataFetched(false);
    setHypothesis({
      ...hypothesis,
      audiogramPredicted: false,
      allGeneData: [],
    });
    setVisualizationData(null);
    setPredictAudioProfiles(true); // Trigger new prediction
    setPredictAudioProfilesCounter(predictAudioProfilesCounter + 1);
    saveIsPredicted(false);
    clearPredictionData();
    clearProcessedTableData();
  };
  const handleUpdate = async () => {
    setLoading(true);
    setShowUpdateButton(false);
    // Update the hypothesis with the new selected genes
    setHypothesis({ ...hypothesis, selectedGenes: selectedGenes });
    const newHyptothesis = {
      ...hypothesis,
      selectedGenes: selectedGenes,
    };
    saveVisualizationState({
      hypothesis: newHyptothesis,
    });

    // Simulate fetching data or other async operations
    await new Promise((resolve) => setTimeout(resolve, 2000)); // Remove this in production

    setLoading(false);
  };

  const handleSelectAllGenes = () => {
    setSelectedGenes(allGenes);
    setHypothesis({ ...hypothesis, selectedGenes: allGenes });
    const newHyptothesis = {
      ...hypothesis,
      selectedGenes: selectedGenes,
    };
    saveVisualizationState({
      hypothesis: newHyptothesis,
    });
  };

  const handleDeselectAllGenes = () => {
    setSelectedGenes([]);
  };

  // Helper function to process clustering data
  const mapClusteringData = (item) => ({
    cluster: item.cluster,
    gene: item.gene,
    x: item.x,
    y: item.y,
    z: item.z,
    // Additional mapping if required
  });

  return (
    <ErrorBoundary clearAllData={clearAllData}>
      <div className={classes.container}>
        <TableComponent />
        {tableData && tableData.length > 0 && (
          <SubmittedDataTable handlePredictClick={handlePredictClick} />
        )}
        {tableData && tableData.length > 0 && predictAudioProfiles && (
          <ProcessAudiogramData
            predictAudioProfilesCounter={predictAudioProfilesCounter}
            customRequest={false}
            genes={hypothesis.selectedGenes}
            usingWalkthrough={true}
          />
        )}
        {tableData &&
          tableData.length > 0 &&
          predictAudioProfiles &&
          !dataFetched && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          )}
        {hypothesis.selectedGenes &&
          visualizationData &&
          tableData &&
          tableData.length > 0 &&
          dataFetched && (
            // {tableData.length > 0 && (
            <>
              <Typography
                variant="h2"
                component="h2"
                className={classes.gridTitle}
              >
                AudioGene Translational Dashboard
              </Typography>
              <div className={classes.container}>
                {/* Gene selection and update button */}
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mb={2}
                >
                  <Typography
                    variant="h6"
                    component="h6"
                    sx={{
                      marginRight: "20px",
                    }}
                  >
                    Select Genes to Visualize:
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="gene-select-label">Genes</InputLabel>
                    <Select
                      labelId="gene-select-label"
                      multiple
                      value={hypothesis.selectedGenes}
                      onChange={handleGeneChange}
                      renderValue={(selected) => selected.join(", ")}
                      className={classes.select}
                    >
                      {allGenes.map((gene) => (
                        <StyledMenuItem key={gene} value={gene}>
                          <Checkbox
                            checked={
                              hypothesis.selectedGenes
                                ? hypothesis.selectedGenes.indexOf(gene) > -1
                                : false
                            }
                          />
                          <ListItemText primary={gene} />
                        </StyledMenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                      marginLeft: "20px",
                    }}
                    onClick={handleSelectAllGenes}
                  >
                    Select All
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "white",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "white",
                      },
                      marginLeft: "20px",
                    }}
                    onClick={handleDeselectAllGenes}
                  >
                    Deselect All
                  </Button>
                  {showUpdateButton && (
                    <Tooltip title="Click to update the visualization with the new genes.">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdate}
                        style={{ marginLeft: 8 }}
                      >
                        Update
                      </Button>
                    </Tooltip>
                  )}
                </Box>

                {/* Loading screen, if necessary */}
                {loading && (
                  <Box className={classes.loadingContainer}>
                    <CircularProgress />
                  </Box>
                )}
              </div>
              {hypothesis.allGeneData ? (
                <>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      marginTop: "20px",
                      marginBottom: "5px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Grid
                      container
                      spacing={3}
                      style={{
                        marginTop: "5px",
                        marginBottom: "40px",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <Grid item xs={12} sm={6}>
                        <Card className={classes.bigCard}>
                          <CardContent className={classes.cardContentBig}>
                            <AudioProfiles usingWalkthrough={true} />
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Card className={classes.bigCard}>
                          <CardContent className={classes.cardContentBig}>
                            <SurfaceViewer usingWalkthrough={true} />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Card
                        className={classes.bigCard}
                        style={{ width: "99%" }}
                      >
                        <CardContent className={classes.cardContentBig}>
                          <ThreeDClustering
                            clusteringDataGreedy={
                              hypothesis.allGeneData.clusteringData.greedy
                            }
                            clusteringDataOriginal={
                              hypothesis.allGeneData.clusteringData.original
                            }
                            geneCounts={hypothesis.allGeneData.geneCounts}
                            geneCountsAfterResampling={
                              hypothesis.allGeneData.geneCountsAfterResampling
                            }
                            table_clustering_data={
                              hypothesis.allGeneData.tableDataCluster
                            }
                            hypothesis={hypothesis}
                            setHypothesis={setHypothesis}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    style={{
                      marginTop: "5px",
                      marginBottom: "40px",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={6}>
                      <Card className={classes.mediumCard}>
                        <CardContent className={classes.cardContentMedium}>
                          <GeneCountChart
                            visualizationData={hypothesis.allGeneData}
                            classBalancingMethods={classBalancingMethods}
                            setClassBalancingMethods={setClassBalancingMethods}
                            totalSamples={totalSamples}
                            setTotalSamples={setTotalSamples}
                            onUpdateData={fetchData} // Passing the fetchData function as a prop
                            selectedGenes={hypothesis.selectedGenes}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Card className={classes.mediumCard}>
                        <CardContent className={classes.cardContentMedium}>
                          <EthnicityGeneScatter
                            data={hypothesis.allGeneData.scatterData}
                            selectedGenes={hypothesis.selectedGenes}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Card className={classes.smallCard}>
                        <CardContent className={classes.cardContentSmall}>
                          <GeneExpressionOverAge
                            data={hypothesis.allGeneData.geneExpressionData}
                            selectedGenes={hypothesis.selectedGenes}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <Button onClick={onNext}>Next</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100vh"
                >
                  <CircularProgress />
                </Box>
              )}
            </>
          )}
      </div>
    </ErrorBoundary>
  );
};

export default Visualization;
