import React, { useState } from "react";
import axios from "axios";
import TokenService from "./token_service";
import {
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Link,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useNavigate } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { dimensions } from "../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#fff", // background color white
    justifyItems: "center",
    alignContent: "center",
  },
  card: {
    backgroundColor: "white", // card background color white
    padding: theme.spacing(4),
    borderRadius: "8px", // rounded corners for the card
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // subtle shadow for depth
    width: dimensions.width / 4.2, // increased width for better layout
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(2), // increased spacing for a clean look
    },
    backgroundColor: "white", // form background color white
  },
  link: {
    color: "black", // link color gold
    "&:hover": {
      textDecoration: "underline", // underline on hover for links
    },
  },
  title: {
    color: "#000", // title color black
    textAlign: "center", // center the title
    marginBottom: theme.spacing(1), // margin below the title
  },
  input: {
    color: "#000", // input text color black
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FFD700", // underline color gold when not focused
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#FFD700", // underline color gold on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FFD700", // underline color gold when focused
    },
    width: dimensions.width / 7, // increased width for better layout
    height: dimensions.height / 25,
    margin: theme.spacing(1), // increased spacing for a clean look
  },
  visibilityIcon: {
    color: "black", // visibility icon color gold
  },
  loginStatusLabel: {
    color: "black", // login status label color gold
    textAlign: "center", // center the login status label
    marginTop: theme.spacing(2), // margin above the login status label
  },
  button: {
    marginTop: 1,
    width: "35%",
    color: "white",
    backgroundColor: "black",
    marginLeft: "auto", // Add this line to center the button
    marginRight: "auto", // Add this line to center the button
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    "&:disabled": {
      backgroundColor: "white",
      color: "black",
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  axios.defaults.withCredentials = true;

  const login = async (event) => {
    event.preventDefault();
    console.log("sending data");
    if (email === "" || password === "") {
      setLoginStatus("No Blank Fields");
    } else if (email.length > 150 || password.length > 50) {
      setLoginStatus("One of The Fields Has Too Many Characters");
    } else {
      const data = {
        email: email,
        password: password,
      };

      const result = await axios.post("/check_login", data).then((response) => {
        if (!response.data.auth) {
          setLoginStatus(response.data.message);
        } else {
          setLoginStatus(
            "Welcome " +
              response.data.result[0].first_name +
              " " +
              response.data.result[0].last_name +
              "! ",
          );
          TokenService.setUser(response.data.tokens);
          window.location.href = "/";
        }
      });
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardContent className={classes.form}>
          <Typography variant="h5" className={classes.title}>
            Sign In
          </Typography>
          <form className={classes.form} onSubmit={login}>
            <TextField
              type="email"
              placeholder="Email"
              InputProps={{
                className: classes.input,
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              InputProps={{
                className: classes.input,
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    className={classes.visibilityIcon}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                ),
              }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Typography className={classes.loginStatusLabel}>
              {loginStatus}
            </Typography>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
            >
              Login
            </Button>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate("/signup")}
              className={classes.link}
            >
              Sign Up
            </Link>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate("/forgotten_password_email")}
              className={classes.link}
            >
              Forgot Password?
            </Link>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
