import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";
import { getCLS, getFID, getLCP } from "web-vitals";

function sendToServer(metric) {
  const body = JSON.stringify(metric);
  // Send the metrics to your server (you'll implement this part next)
  fetch("/vitals", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
}

getCLS(sendToServer);
getFID(sendToServer);
getLCP(sendToServer);
function sendToGA(metric) {
  ReactGA.event({
    category: "Web Vitals",
    action: metric.name,
    value: Math.round(metric.value),
    label: metric.id,
    nonInteraction: true,
  });
}

getCLS(sendToGA);
getFID(sendToGA);
getLCP(sendToGA);

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

//Index Page servers an App.js component
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <App />
  </>,
);
