import React from "react";
import "./audioProfiles.css";
import TwoDPlot from "./TwoDPlot";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "50px",
    marginBottom: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // Ensure the container takes full width
  },
}));

const AudioProfiles = ({ usingWalkthrough }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <TwoDPlot usingWalkthrough={usingWalkthrough} />
    </div>
  );
};

export default AudioProfiles;
