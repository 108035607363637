import "./workspaces.css";
import React, { useState, useEffect, useLayoutEffect } from "react";
import UserAuthorization from "../Utilities/user_auth";
import axios from "axios";

export default function Workspaces() {
  const [userInfo, setUserInfo] = useState({});

  const [userAuth, setUserAuth] = useState(false);

  const [userId, setUserId] = useState(0);

  const [userEmail, setUserEmail] = useState("");

  const [rows, setRows] = useState([]);

  useEffect(() => {
    (async () => {
      const user_info = await UserAuthorization();
      setUserInfo(user_info);
      setUserId(user_info.user_id);
    })();
  }, [userId]);

  const getUserWorkspaces = async () => {
    const data = {
      id: userId,
    };
    var workspace_rows = [];
    await axios.post("/find_workspaces", data).then((response) => {
      console.log(response);
      console.log(response.data.length);

      for (var i = 0; i < response.data.length; i++) {
        workspace_rows.push(
          <div className="body-shape">
            <br></br>
            <p>Workspace Name: {response.data[i].name} </p>
            <br></br>
            <p>Date Created: {response.data[i].timestamp.slice(0, 10)}</p>
            <br></br>
            <p>Number of Audiograms: </p>
            <br></br>
            <button>Load Workspace</button>
          </div>,
        );
      }
    });
    setRows(workspace_rows);

    console.log(rows);
  };

  return (
    <body class="workspaces-body">
      <div class="workspaces-header">Workspaces</div>
      <br></br>
      <div>
        <br></br>
        <button onClick={getUserWorkspaces}>
          {" "}
          Click to get your workspaces{" "}
        </button>
        <br></br>
        {rows}
      </div>
    </body>
  );
}
