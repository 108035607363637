// This file is for accessing the local storage for access and refresh tokens
// both of these deal with user authroization

const getRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.refresh_token;
};

const getAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.access_token;
};

const getAccessAdminToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.access_admin_token;
};

const getUserEmail = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user?.email;
};

const updateNewAccessToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user"));
  user.access_token = token;
  localStorage.setItem("user", JSON.stringify(user));
};

const updateNewAccessAdminToken = (token) => {
  let user = JSON.parse(localStorage.getItem("user"));
  user.access_admin_token = token;
  localStorage.setItem("user", JSON.stringify(user));
};

const getUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const TokenService = {
  getRefreshToken,
  getAccessToken,
  getAccessAdminToken,
  getUserEmail,
  updateNewAccessToken,
  updateNewAccessAdminToken,
  getUser,
  setUser,
  removeUser,
};

export default TokenService;
