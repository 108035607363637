import React, { createContext, useContext, useState } from "react";

const AudioProfileContext = createContext();

export const useAudioProfile = () => useContext(AudioProfileContext);

export const AudioProfileProvider = ({ children }) => {
  const [audioProfileData, setAudioProfileData] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("audioProfileData");
    return saved ? JSON.parse(saved) : null;
  });

  console.log("Reloading audioProfileData", audioProfileData);

  const [isPredicted, setIsPredicted] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("isPredicted");
    return saved ? JSON.parse(saved) : false;
  });

  const saveIsPredicted = (isPredictedBool) => {
    localStorage.setItem("isPredicted", JSON.stringify(isPredictedBool));
    setIsPredicted(isPredictedBool);
  };

  const [predictionData, setPredictionData] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("predictionData");
    return saved ? JSON.parse(saved) : null;
  });

  const savePredictionData = (data, callback) => {
    setPredictionData(data);
    localStorage.setItem("predictionData", JSON.stringify(data));
    if (callback && typeof callback === "function") {
      callback(); // Execute the callback function after the state has been updated
    }
  };

  const clearPredictionData = () => {
    setPredictionData(null);
    localStorage.removeItem("predictionData");
  };

  const [tableData, setTableData] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("tableData");
    return saved ? JSON.parse(saved) : null;
  });

  const saveTableData = (data, callback) => {
    setTableData(data);
    localStorage.setItem("tableData", JSON.stringify(data));
    if (callback && typeof callback === "function") {
      callback(); // Execute the callback function after the state has been updated
    }
  };

  const clearTableData = () => {
    setTableData(null);
    localStorage.removeItem("tableData");
  };

  const [resamplingData, setResamplingData] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("resamplingData");
    return saved
      ? JSON.parse(saved)
      : {
          resample: false,
          resampleMethod: "SMOTE",
          minThreshold: 50,
          maxThreshold: 250,
          totalSamples: 1000,
        };
  });

  const saveResamplingData = (data, callback) => {
    setResamplingData(data);
    localStorage.setItem("resamplingData", JSON.stringify(data));
    if (callback && typeof callback === "function") {
      callback(); // Execute the callback function after the state has been updated
    }
  };

  const clearResamplingData = () => {
    setResamplingData({
      resample: false,
      resampleMethod: "SMOTE",
      minThreshold: 50,
      maxThreshold: 250,
      totalSamples: 1000,
    });
    localStorage.removeItem("resamplingData");
  };

  const [processedTableData, setProcessedTableData] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("processedTableData");
    return saved ? JSON.parse(saved) : null;
  });

  const saveProcessedTableData = (data, callback) => {
    setProcessedTableData(data);
    localStorage.setItem("processedTableData", JSON.stringify(data));
    if (callback && typeof callback === "function") {
      callback(); // Execute the callback function after the state has been updated
    }
  };

  const clearProcessedTableData = () => {
    setProcessedTableData(null);
    localStorage.removeItem("processedTableData");
  };

  const saveAudioProfileData = (data, callback) => {
    setAudioProfileData(data);
    localStorage.setItem("audioProfileData", JSON.stringify(data));
    if (callback && typeof callback === "function") {
      callback(); // Execute the callback function after the state has been updated
    }
  };

  const clearAudioProfileData = () => {
    setAudioProfileData(null);
    localStorage.removeItem("audioProfileData");
  };

  const [visualizationState, setVisualizationState] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("visualizationState");
    return saved ? JSON.parse(saved) : {};
  });

  const saveVisualizationState = (state) => {
    return new Promise((resolve) => {
      console.log("saving state", state);
      setVisualizationState(state);
      localStorage.setItem("visualizationState", JSON.stringify(state));
      resolve(state); // Resolve the promise with the new state
    });
  };

  const clearVisualizationState = () => {
    setVisualizationState({});
    localStorage.removeItem("visualizationState");
  };

  const clearAllState = () => {
    clearAudioProfileData();
    clearVisualizationState();
    clearPredictionData();
    clearProcessedTableData();
    clearTableData();
    setIsPredicted(false);
  };

  return (
    <AudioProfileContext.Provider
      value={{
        audioProfileData,
        saveAudioProfileData,
        clearAudioProfileData,
        isPredicted,
        setIsPredicted,
        visualizationState,
        saveVisualizationState,
        clearVisualizationState,
        predictionData,
        setPredictionData,
        savePredictionData,
        saveIsPredicted,
        clearPredictionData,
        tableData,
        saveTableData,
        processedTableData,
        saveProcessedTableData,
        clearProcessedTableData,
        clearAllState,
        resamplingData,
        saveResamplingData,
        clearResamplingData,
      }}
    >
      {children}
    </AudioProfileContext.Provider>
  );
};
