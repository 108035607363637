import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Figure from "../Images/Figure.jpg";
import audiogramUtilityFigure from "../Images/audiogene_mindmap.png";
import devProcessAudioGene from "../Images/agDevProcess.jpg";
import audioProfile from "../Images/audioProfile.png";
import AudioProfileSurface from "../Images/audioProfileSurface.png";
import phenomeToGeneome from "../Images/phenome_to_genome.png";
import { dimensions } from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  card: {
    marginBottom: theme.spacing(3),
    boxShadow: "0 3px 5px 2px rgba(128, 128, 128, .3)",
  },
  media: {
    width: "100%", // Ensures the image takes the full width of its container
    height: "auto",
    display: "block",
    objectFit: "contain", // Image is scaled to maintain its aspect ratio while fitting within the element’s content box
    maxHeight: "80vh", // Limits the image height to 80% of the viewport height, ensuring it fits within most screens
  },
  mediumMedia: {
    width: "100%",
    height: "auto",
    display: "block",
    objectFit: "contain",
    maxHeight: "60vh", // A bit smaller for medium-sized media
  },
  largeMedia: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    maxHeight: "80vh", // Same as media for consistency
  },
  paragraph: {
    fontSize: 28,
  },
  smallParagraph: {
    fontSize: 20,
  },
  title: {
    fontSize: 48,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    fontSize: 42,
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
  },
  subTitle1: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
}));

export default function Home() {
  const classes = useStyles();
  const [imageHeight, setImageHeight] = useState("80vh"); // Default height

  useEffect(() => {
    function handleResize() {
      // Set image height based on current viewport height
      setImageHeight(`${window.innerHeight * 0.8}px`);
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial size

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h1" className={classes.title}>
            Welcome to AudioGene!
          </Typography>
          <Typography variant="h5" component="h2" className={classes.subTitle}>
            What is AudioGene?
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.paragraph}
          >
            AudioGene is a suite of supervised learning models that leverage
            audiometric data and patient age to predict genotypes.
          </Typography>
          <CardMedia
            component="img"
            className={classes.media}
            image={audiogramUtilityFigure}
            title="Audiogram Utility"
          />
          <Typography variant="h5" component="h2" className={classes.subTitle}>
            The Utility and Significance of AudioGene
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.paragraph}
          >
            AudioGene allows users to move from the phenome to the genome,
            making it a valuable resource for predicting concordance between
            audiograms and genetic variants identified by comprehensive genetic
            screening panels in persons with hearing loss.
          </Typography>
          <CardMedia
            component="img"
            className={classes.mediumMedia}
            image={phenomeToGeneome}
            title="Phenome to Genome"
            onError={(e) => {
              e.target.style.display = "none";
            }} // Hide if there's an error
          />
          <Typography variant="h5" component="h2" className={classes.subTitle}>
            The Development Process of AudioGene
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.paragraph}
          >
            The development process of AudioGene involves the use of machine
            learning algorithms to analyze audiometric data and patient age,
            resulting in accurate genotype predictions.
          </Typography>
          <CardMedia
            component="img"
            className={classes.largeMedia}
            image={devProcessAudioGene}
            title="Development Process of AudioGene"
            onError={(e) => {
              e.target.style.display = "none";
            }} // Hide if there's an error
          />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2" className={classes.title}>
            AudioGene Tools
          </Typography>
          <Typography variant="h5" component="h2" className={classes.subTitle}>
            AudioProfiles
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.paragraph}
          >
            This feature aggregates all audiograms in our dataset to generate an
            average audiogram with the expected hearing loss progression for a
            particular gene. For example, this tool could predict what the
            audiogram of someone with <i>EYA4</i>-related hearing loss could
            look like when they are 65.
          </Typography>
          <CardMedia
            component="img"
            className={classes.largeMedia}
            image={audioProfile}
            title="Audio Profile"
            onError={(e) => {
              e.target.style.display = "none";
            }} // Hide if there's an error
          />
          <Typography variant="h5" component="h2" className={classes.subTitle}>
            Surface Viewer
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.paragraph}
          >
            This feature can be used to define audioprofile surfaces, a novel
            method of representing audiometric data that incorporates age as the
            third dimension. In addition to frequency and decibels, age is
            included to show average hearing thresholds over time for
            gene-specific types of hearing loss. For example, this tool could
            show what the audioprofile surface of someone with <i>EYA4</i>
            -related hearing loss could look like.
          </Typography>
          <CardMedia
            component="img"
            className={classes.largeMedia}
            image={AudioProfileSurface}
            title="Figure"
            onError={(e) => {
              e.target.style.display = "none";
            }} // Hide if there's an error
          />
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            className={classes.subTitle}
          >
            Visualizing Gene Specific Natural History
          </Typography>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            className={classes.subTitle1}
          >
            Significance of Natural History:
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.smallParagraph}
          >
            The loss of any sense can be an intimidating thought. We believe
            that enabling clinicians and patients to understand how hearing loss
            may progress can play a critical role in managing disease. To assist
            this, we developed the Audioprofile Surface Viewer. This tool allows
            you to plot your own audiogram on a 3-D projection built from
            audiograms provided by others with hearing loss caused by the
            selected gene. Please remember that every individual experiences
            hearing loss in a unique way. Being above or below the hearing loss
            typically experienced by others is not a guarantee of how your
            condition will continue to progress.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.smallParagraph}
          >
            These surfaces are created from audiograms provided by real people.
            Each person has experienced unique circumstances that may have
            caused additional hearing loss such as frequent or intense exposure
            to loud noise. Another major difference that’s unaccounted for by
            our surfaces is different types of mutations on the same gene. For
            example, mutations in the <i>KCNQ4</i> gene are well known to be of
            two types: missense & nonsense. At this time, we are only able to
            provide a single surface that merges these two types. In the future,
            we hope to refine our surfaces and expand the number available to
            account for these differences.
          </Typography>
          <Typography variant="h5" component="h2" className={classes.subTitle}>
            Run AudioGene
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.paragraph}
          >
            Users may input their own audiometric data, and AudioGene will
            generate a predicted genotype.
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" component="h2" className={classes.subTitle}>
            Funding
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.paragraph}
          >
            This project was supported in part by NIH-NIDCD grants DC002842,
            DC012049, and DC017955.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
