import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Link,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { TabContent } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  card: {
    color: "#000", // Black text
    margin: theme.spacing(3),
    padding: theme.spacing(2),
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // subtle shadow for depth
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  subTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: "1.2rem",
    marginBottom: theme.spacing(2),
  },
  link: {
    color: "#000", // Black text
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: "#FFD700", // Gold text
    },
    fontSize: "1.1rem",
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  // Updated table styles to match the image
  table: {
    minWidth: 650,
    borderRadius: 5,
    "& th": {
      fontWeight: "bold",
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    "& td, & th": {
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
    },
  },
  // Add this for the header row to match the image style
  headerRow: {
    "& th": {
      backgroundColor: "black",
    },
  },
  // Add this for the body rows to have alternating colors like in the image
  bodyRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

//CitingUs component contains only HTML containing information for citing the research group
export default function CitingUs() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h2" className={classes.title}>
          Citing Us
        </Typography>
        <Typography variant="h3" className={classes.subTitle}>
          Please cite the following papers when using AudioGene:
        </Typography>
        <Box className={classes.section}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell>
                  <Typography variant="h6" className={classes.subTitle}>
                    Citation
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6" className={classes.subTitle}>
                    PubMed Link
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" className={classes.text}>
                    Hildebrand MS, DeLuca AP, Taylor KR, Hoskinson DP, Hur IA,
                    Tack D, McMordie SJ, Huygen PL, Casavant TL, Smith RJ.{" "}
                    <i>
                      A contemporary review of AudioGene audioprofiling: a
                      machine-based candidate gene prediction tool for autosomal
                      dominant nonsyndromic hearing loss
                    </i>
                    . Laryngoscope. 2009 Nov;<strong>119</strong>(11):2211-5.
                    doi: 10.1002/lary.20664.
                  </Typography>
                </TableCell>
                <TableCell>
                  <Link
                    href="https://pubmed.ncbi.nlm.nih.gov/19780026/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    PMID: 19780026
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" className={classes.text}>
                    Taylor KR, Deluca AP, Shearer AE, Hildebrand MS,
                    Black-Ziegelbein EA, Anand VN, Sloan CM, Eppsteiner RW,
                    Scheetz TE, Huygen PL, Smith RJ, Braun TA, Casavant TL.{" "}
                    <i>
                      AudioGene: predicting hearing loss genotypes from
                      phenotypes to guide genetic screening
                    </i>
                    . Hum Mutat. 2013 Apr;<strong>34</strong>(4):539-45. doi:
                    10.1002/humu.22268. Epub 2013 Feb 19.
                  </Typography>
                </TableCell>
                <TableCell>
                  <Link
                    href="https://pubmed.ncbi.nlm.nih.gov/23280582/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    PMID: 23280582
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" className={classes.text}>
                    Taylor KR, Booth KT, Azaiez H, Sloan CM, Kolbe DL, Glanz EN,
                    Shearer AE, DeLuca AP, Anand VN, Hildebrand MS, Simpson AC,
                    Eppsteiner RW, Scheetz TE, Braun TA, Huygen PL, Smith RJ,
                    Casavant TL.{" "}
                    <i>Audioprofile Surfaces: The 21st Century Audiogram</i>.
                    Ann Otol Rhinol Laryngol. 2016 May;<strong>125</strong>
                    (5):361-8. doi: 10.1177/0003489415614863. Epub 2015 Nov 3.
                  </Typography>
                </TableCell>
                <TableCell>
                  <Link
                    href="https://pubmed.ncbi.nlm.nih.gov/26530094/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    PMID: 26530094
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" className={classes.text}>
                    Thorpe, R. K., Walls, W. D., Corrigan, R., Schaefer, A.,
                    Wang, K., Huygen, P., Casavant, T. L., & Smith, R. J. H.
                    (2022).{" "}
                    <i>
                      AudioGene: refining the natural history of KCNQ4, GSDME,
                      WFS1, and COCH-associated hearing loss
                    </i>
                    . Human genetics, <strong>141</strong>(3-4), 877–887.
                    https://doi.org/10.1007/s00439-021-02424-7
                  </Typography>
                </TableCell>
                <TableCell>
                  <Link
                    href="https://pubmed.ncbi.nlm.nih.gov/35038006/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    PMID: 35038006
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" className={classes.text}>
                    Thorpe, R. K., Azaiez, H., Wu, P., Wang, Q., Xu, L., Dai,
                    P., Yang, T., Schaefer, G. B., Peters, B. R., Chan, K. H.,
                    Schatz, K. S., Bodurtha, J., Robin, N. H., Hirsch, Y.,
                    Rahbeeni, Z. A., Yuan, H., & Smith, R. J. H. (2022).{" "}
                    <i>
                      The natural history of OTOF-related auditory neuropathy
                      spectrum disorders: a multicenter study
                    </i>
                    . Human genetics, <strong>141</strong>(3-4), 853–863.
                    https://doi.org/10.1007/s00439-021-02340-w
                  </Typography>
                </TableCell>
                <TableCell>
                  <Link
                    href="https://pubmed.ncbi.nlm.nih.gov/34424407/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    PMID: 34424407
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" className={classes.text}>
                    Walls, W. D., Moteki, H., Thomas, T. R., Nishio, S. Y.,
                    Yoshimura, H., Iwasa, Y., Frees, K. L., Nishimura, C. J.,
                    Azaiez, H., Booth, K. T., Marini, R. J., Kolbe, D. L.,
                    Weaver, A. M., Schaefer, A. M., Wang, K., Braun, T. A.,
                    Usami, S. I., Barr-Gillespie, P. G., Richardson, G. P.,
                    Smith, R. J., Casavant, T. L. (2020).{" "}
                    <i>
                      A comparative analysis of genetic hearing loss phenotypes
                      in European/American and Japanese populations
                    </i>
                    . Human genetics, <strong>139</strong>(10), 1315–1323.
                    https://doi.org/10.1007/s00439-020-02174-y
                  </Typography>
                </TableCell>
                <TableCell>
                  <Link
                    href="https://pubmed.ncbi.nlm.nih.gov/32382995/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                  >
                    PMID: 32382995
                  </Link>
                </TableCell>
              </TableRow>
              {/* Special citation for the AudioGene HomePage */}
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant="body1" className={classes.text}>
                    Citing the AudioGene HomePage
                  </Typography>
                  <Typography variant="body1" className={classes.text}>
                    When you use data from the AudioGene Website in your
                    publication, please cite it in the following format:
                  </Typography>
                  <Typography variant="body1" className={classes.text}>
                    DeSollar RB, Azaiez H, Smith RJH, Casavant, T. L. (2024).
                    AudioGene `Page your citing information from (for example,
                    Home Page would be HomePage)`. https://audiogene.uiowa.edu.
                    (If you are referring to specific data, please add the month
                    and year when you retrieved the data.)
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  );
}
