import React from "react";
import Plot from "react-plotly.js";
import { dimensions } from "../constants";

const GeneExpressionOverAge = ({ data, selectedGenes }) => {
  let genes = [...new Set(data.map((item) => item.gene))];
  console.log("In GeneExpressionOverAge with data:", data);
  // filter genes based on the selectedGenes
  if (selectedGenes.length > 0) {
    genes = genes.filter((gene) => selectedGenes.includes(gene));
  }

  // Sort genes in reverse alphabetical order
  genes.sort().reverse();

  // Map each gene to its encoded value for correct y-axis mapping
  const geneToEncoded = {};
  // Initialize a map to count occurrences of each gene
  const geneCounts = {};
  data.forEach((point) => {
    if (genes.includes(point.gene)) {
      geneToEncoded[point.gene] = point.geneEncoded;
      // Count occurrences
      if (!geneCounts[point.gene]) {
        geneCounts[point.gene] = 0;
      }
      geneCounts[point.gene] += 1;
    }
  });

  // Find the maximum encoded value to invert the y-axis values
  const maxEncodedValue = Math.max(...Object.values(geneToEncoded));

  const traces = genes.map((gene) => {
    const genePoints = data.filter((point) => point.gene === gene);
    return {
      x: genePoints.map((point) => point.age),
      y: genePoints.map((point) => maxEncodedValue - geneToEncoded[gene] + 1), // Invert y value for correct order
      mode: "markers",
      type: "scatter",
      // Append the count of each gene to its name for the legend
      name: `${gene} (${geneCounts[gene]})`,
      style: { width: 8, fontStyle: "italic" },
      marker: { size: 4 },
    };
  });

  return (
    <Plot
      data={traces}
      layout={{
        title: "Distribution of Training Data By Age",
        xaxis: { title: "Age" },
        yaxis: {
          title: "Gene",
          // Invert tickvals for correct y-axis order
          tickvals: Object.values(geneToEncoded).map(
            (val) => maxEncodedValue - val + 1,
          ),
          ticktext: Object.keys(geneToEncoded),
          tickfont: { size: 14, style: "italic" },
        },
        hovermode: "closest",
        margin: { l: 100, r: 250, t: 50, b: 75 },
        width: dimensions.width / 2,
        height: dimensions.height / 1.8,
      }}
    />
  );
};

export default GeneExpressionOverAge;
