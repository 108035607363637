import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import casavant from "../Images/casavant.jpeg";
import richard_smith from "../Images/richard_smith.png";
import ms from "../Images/ms.jpeg";
import tb from "../Images/tb1.png";
import { Box } from "@mui/material";
import { dimensions } from "../constants";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    marginBottom: "20px",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    padding: "20px",
    width: "80%", // set the width to 80% of the screen
    margin: "0 auto", // center the container
  },
  root: {
    width: "100%", // make the card stretch to fill the container
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // subtle shadow for depth
  },
  media: {
    marginTop: "20px",
    marginBottom: "20px",
    height: dimensions.height / 2.5, // fixed height for the image
    display: "block", // use block to apply margin auto
    marginLeft: "auto",
    marginRight: "auto",
    width: "75%", // set width as a percentage of the card width
    objectFit: "contain", // maintain aspect ratio without cropping
  },
  section: {
    marginTop: "20px",
  },
  sectionContact: {
    marginTop: "40px",
  },
});

const contributors = [
  {
    image: casavant,
    title: "Dr Thomas L. Casavant, Ph.D.",
    description: [
      "Roy J. Carver, Jr. Chair in Bioinformatics and Computational Biology",
      "Director: UI Center for Bioinformatics and Computational Biology (CBCB)",
      "co-PI: John and Marcia Carver Nonprofit Genetic Testing Laboratory",
      "Depts. of Electrical and Computer, Biomedical Engineering, and Ophthalmology",
      "Director: Parallel Processing Laboratory",
      "Member: Genetics Ph.D. Program Faculty, Applied Math and Computational Sci. Pgm, and Coordinated Lab. for Computational Genomics",
      "Co-director: Holden Cancer Center Program in Cancer Genetics and Computational Biology",
    ],
    contactInfo: [
      "University of Iowa",
      "Iowa City, IA 52242 (USA)",
      "Tel. 319-335-5953",
      "Fax. 319-384-0944",
      "Email: tomc@eng.uiowa.edu",
    ],
  },
  {
    image: richard_smith,
    title: "Dr Richard Smith, MD",
    description: [
      "Director, Molecular Otolaryngology and Renal Research Laboratories (MORL)",
      "Director, The Iowa Institute of Human Genetics (IIHG)",
      "Vice Chair - Department of Otolaryngology",
      "Professor of Otolaryngology, Molecular Physiology & Biophysics, Pediatrics and Internal Medicine (Division of Nephrology)",
      "Carver College of Medicine",
    ],
    contactInfo: [
      "University of Iowa",
      "Iowa City, IA 52242 (USA)",
      "Tel. 319 335-6501",
      "Fax. 319 353-5869",
      "Email: richard-smith@uiowa.edu",
    ],
  },
  {
    image: ms,
    title: "Michael Schnieders, Ph.D.",
    description: [
      "Associate Professor, Roy J. Carver Dept. of Biomedical Engineering and Dept. of Biochemistry, University of Iowa",
    ],
    contactInfo: [
      "University of Iowa",
      "Iowa City, IA 52242 (USA)",
      "Tel. (650) 995-3526",
      "Fax. (319) 335-5631",
      "Email: michael-schnieders@uiowa.edu",
    ],
  },
  {
    image: tb,
    title: "Terry Braun, Ph.D.",
    description: [
      "Professor, Roy J. Carver Dept. of Biomedical Engineering, University of Iowa",
    ],
    contactInfo: [
      "University of Iowa",
      "Iowa City, IA 52242 (USA)",
      "Tel. (319) 335-6285",
      "Fax. (319) 335-5631",
      "Email: terry-braun@uiowa.edu",
    ],
  },
];

export default function AboutUs() {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {contributors.map((contributor, index) => (
        <Card className={classes.root} key={index}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} sm={4}>
              <CardMedia
                className={classes.media}
                image={contributor.image}
                title={contributor.title}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {contributor.title}
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.section}
                >
                  <strong>Description:</strong>
                </Typography>
                {contributor.description.map((desc, i) => (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.section}
                    key={i}
                  >
                    • {desc}
                  </Typography>
                ))}
              </CardContent>
            </Grid>
            <Grid item xs={12} sm={4}>
              <CardContent>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.sectionContact}
                >
                  <strong>Contact Info:</strong>
                </Typography>
                {contributor.contactInfo.map((info, i) => (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.section}
                    key={i}
                  >
                    • {info}
                  </Typography>
                ))}
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      ))}
    </Box>
  );
}
