import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { dimensions } from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    overflow: "auto",
  },
  card: {
    width: dimensions.width,
    height: dimensions.height / 1.5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    marginBottom: theme.spacing(3),
    boxShadow: "0 3px 5px 2px rgba(128, 128, 128, .3)",
  },
  media: {
    height: dimensions.height / 1.8,
    width: "60%", // Adjust based on your needs
    margin: "auto", // Center the video
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  helpCard: {
    width: dimensions.width,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    boxShadow: "0 3px 5px 2px rgba(128, 128, 128, .3)",
  },
  helpTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  helpText: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
}));

const videos = [
  {
    title: "AudioProfile Demo",
    url: "https://www.youtube.com/embed/s72v-ZwFS8k?si=shiRMvEqc8b0RztW",
  },
  {
    title: "AudioProfile Surface Demo",
    url: "https://www.youtube.com/embed/xkrHWzpp6Gw?si=y_89r-ZodqU5elAp",
  },
  {
    title: "Running AudioGene Demo",
    url: "https://www.youtube.com/embed/IMwICgSoAFU?si=nV716dKgvxd1RUiQ",
  },
  {
    title: "Running AGTD Demo",
    url: "https://www.youtube.com/embed/edNFLOwx0Dc?si=KqxUnqxgsDXORjr7",
  },
];

export default function YoutubeDemo() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {videos.map((video, index) => (
        <Card key={index} className={classes.card}>
          <CardContent>
            <Typography variant="h5" className={classes.title}>
              {video.title}
            </Typography>
            <CardMedia
              component="iframe"
              className={classes.media}
              src={video.url}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </CardContent>
        </Card>
      ))}
      <Card className={classes.helpCard}>
        <CardContent>
          <Typography variant="h6" className={classes.helpTitle}>
            Tips and Tricks
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            - Double-click a gene in the legend of a plot to isolate that gene.
            Double-click it again to select all genes.
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            - Hover over a plot to highlight the cluster. Be careful not to
            accidentally switch clusters by hovering over another bar.
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            - Use the "Reset View" button to return to the default camera
            position in 3D plots.
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            - Toggle the "Show Percentage" switch to view gene distribution as
            percentages instead of counts.
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            - Use the "Visualization Method" dropdown to switch between
            different visualization modes.
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            - Double-click to rescale the y-axis to local min and max in the
            AudioProfile plots.
          </Typography>
          <Typography variant="body1" className={classes.helpText}>
            - To select a new cluster to be highlighted from the bar chart,
            click on a bar in the bar chart. This will then highlight the
            clicked cluster in the 3D plot.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
