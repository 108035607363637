// src/Components/GeneCountChart.js
import React, { useState, useEffect } from "react";
import Plot from "react-plotly.js";
import { dimensions } from "../constants";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Checkbox,
  ListItemText,
  Input,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useAudioProfile } from "../contexts/AudioProfileContext";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: dimensions.height / 2,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
  },
  plotContainer: {
    width: "100%",
    height: "100%",
  },
  controlsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "left",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  formControl: {
    minWidth: dimensions.width / 6,
    maxWidth: 300,
    marginRight: theme.spacing(2),
  },
  updateButton: {
    marginTop: theme.spacing(2),
  },
  updateButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  menuItem: {
    minWidth: dimensions.width / 6,
    maxWidth: 300,
    color: "black",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: dimensions.width / 10,
  },
  button: {
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "#f7f7f7",
    },
    margin: theme.spacing(2),
  },
}));

const GeneCountChart = ({
  visualizationData,
  classBalancingMethods,
  setClassBalancingMethods,
  onUpdateData,
  totalSamples,
  setTotalSamples,
  selectedGenes,
}) => {
  const classes = useStyles();

  const { saveResamplingData } = useAudioProfile();

  const [localMinThreshold, setLocalMinThreshold] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("minThreshold");
    return saved ? JSON.parse(saved) : 50;
  });
  const [localMaxThreshold, setLocalMaxThreshold] = useState(() => {
    // Attempt to load saved state from local storage
    const saved = localStorage.getItem("maxThreshold");
    return saved ? JSON.parse(saved) : 250;
  });
  const [dataLoading, setDataLoading] = useState(false);
  const [localTotalSamples, setLocalTotalSamples] = useState(totalSamples);

  // Convert the data object to an array of [gene, count] pairs and sort them in reverse order by count
  const dataArray = Object.entries(visualizationData.geneCounts).sort(
    (a, b) => a[1] - b[1],
  );
  const [dataArrayState, setDataArrayState] = useState(dataArray);
  const [resampledData, setResampledData] = useState(dataArrayState);
  const [revision, setRevision] = useState(0);
  const [useAfterResampling, setUseAfterResampling] = useState({
    // try to get from local storage
    useAfterResampling: localStorage.getItem("useAfterResampling") || false,
  });

  useEffect(() => {
    // This will be called whenever `visualizationData` changes.
    // Perform any additional operations you need when visualizationData updates.
    setRevision((prev) => prev + 1); // Correctly increment revision without causing re-renders
  }, [visualizationData]);

  useEffect(() => {
    filterNewData();
  }, [selectedGenes, useAfterResampling]);

  const filterNewData = () => {
    // Filter newDataArray to include only selected genes
    console.log("useAfterResampling for filtering", useAfterResampling);
    // let newDataArray = useAfterResampling
    //   ? Object.entries(visualizationData.geneCountsAfterResampling || {})
    //   : Object.entries(visualizationData.geneCounts || {});
    let newDataArray = Object.entries(
      visualizationData.geneCountsAfterResampling || {},
    );

    console.log("newDataArray", newDataArray);
    // Filter newDataArray to include only selected genes
    newDataArray = newDataArray.filter(([gene]) =>
      selectedGenes.includes(gene),
    );

    // Sort and process newDataArray
    newDataArray.sort((a, b) => a[1] - b[1]);

    setResampledData(newDataArray);
  };

  const resampleAllData = async () => {
    setDataLoading(true);
    setTotalSamples(localTotalSamples);
    // save to local storage
    localStorage.setItem("minThreshold", localMinThreshold);
    localStorage.setItem("maxThreshold", localMaxThreshold);
    console.log("Resampling data...");
    await new Promise((resolve) => {
      saveResamplingData(
        {
          resample: true,
          resampleMethod: classBalancingMethods,
          minThreshold: localMinThreshold,
          maxThreshold: localMaxThreshold,
          totalSamples: localTotalSamples,
        },
        resolve,
      );
    });
    try {
      await onUpdateData(true, localMinThreshold, localMaxThreshold);
      // save to local storage
      localStorage.setItem("useAfterResampling", true);
      setUseAfterResampling(true);
      console.log("Data updated successfully");
    } catch (error) {
      console.error("Failed to update data:", error);
    } finally {
      setDataLoading(false);
    }
  };

  const resetDataToDefault = async () => {
    setDataLoading(true);
    setTotalSamples(localTotalSamples);
    console.log("Resetting data to default...");
    await new Promise((resolve) => {
      saveResamplingData(
        {
          resample: false,
          resampleMethod: classBalancingMethods,
          minThreshold: localMinThreshold,
          maxThreshold: localMaxThreshold,
          totalSamples: localTotalSamples,
        },
        resolve,
      );
    });
    try {
      await onUpdateData(false);
      // save to local storage
      localStorage.setItem("useAfterResampling", false);
      console.log("Data updated successfully in resetDataToDefault");
      setUseAfterResampling(false);
    } catch (error) {
      console.error("Failed to update data:", error);
    } finally {
      setDataLoading(false);
    }
  };

  // Update the layout configuration for the Plot component
  const getLayoutConfig = () => {
    const layoutConfig = {
      title: "Gene Counts",
      dragmode: "zoom", // Enable zooming
      margin: {
        l: 50,
        r: 100,
        b: 100,
        t: 50,
        pad: 4,
      },
      height: dimensions.height / 1.6,
      width: dimensions.width / 2.2,
      xaxis: {
        title: "Genes",
        tickangle: 45,
        tickfont: {
          size: 10,
          style: "italic",
        },
      },
      yaxis: {
        title: "Count",
      },
    };

    return layoutConfig;
  };

  return (
    <div className={classes.container}>
      {dataLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={classes.controlsContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="class-balancing-method-label">
                Class Balancing Method
              </InputLabel>
              <Select
                labelId="class-balancing-method-label"
                id="class-balancing-method-select"
                multiple
                value={classBalancingMethods}
                onChange={(event) => {
                  const value = event.target.value;
                  // Check if the last selected value is not "RandomSampling"
                  const lastSelectedValue = value[value.length - 1];
                  if (lastSelectedValue !== "RandomSampling") {
                    // If "RandomSampling" is currently selected, unselect it
                    if (classBalancingMethods.includes("RandomSampling")) {
                      setClassBalancingMethods([lastSelectedValue]);
                    } else {
                      // Otherwise, just update the selected methods
                      setClassBalancingMethods(value);
                    }
                  } else {
                    // If "RandomSampling" is the last selected value, unselect all others
                    setClassBalancingMethods(["RandomSampling"]);
                  }
                }}
                input={<Input />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                <MenuItem value="SMOTE" className={classes.menuItem}>
                  <Checkbox
                    checked={classBalancingMethods.indexOf("SMOTE") > -1}
                  />
                  <ListItemText primary="Upsample Data using SMOTE" />
                </MenuItem>
                <MenuItem value="NearMiss" className={classes.menuItem}>
                  <Checkbox
                    checked={classBalancingMethods.indexOf("NearMiss") > -1}
                  />
                  <ListItemText primary="Downsample Data using NearMiss" />
                </MenuItem>
                <MenuItem value="RandomSampling" className={classes.menuItem}>
                  <Checkbox
                    checked={
                      classBalancingMethods.indexOf("RandomSampling") > -1
                    }
                  />
                  <ListItemText primary="Random Sampling (No Balancing)" />
                </MenuItem>
              </Select>
            </FormControl>
            {!classBalancingMethods.includes("RandomSampling") ? (
              <>
                <TextField
                  id="min-threshold"
                  label="Min Threshold"
                  className={classes.textField}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={localMinThreshold}
                  onChange={(event) => {
                    const value = event.target.value;
                    setLocalMinThreshold(value === "" ? "" : Number(value));
                  }}
                />
                <TextField
                  id="max-threshold"
                  label="Max Threshold"
                  className={classes.textField}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={localMaxThreshold}
                  onChange={(event) => {
                    const value = event.target.value;
                    setLocalMaxThreshold(value === "" ? "" : Number(value));
                  }}
                />
              </>
            ) : (
              <TextField
                id="total-samples"
                label="Total # Of Samples"
                type="number"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={localTotalSamples}
                onChange={(event) => {
                  const value = event.target.value;
                  setLocalTotalSamples(value === "" ? "" : Number(value));
                }}
              />
            )}
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                resampleAllData();
              }}
            >
              Rebalance All Data
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                resetDataToDefault();
              }}
            >
              Reset Data to Default
            </Button>
          </div>
          <div className={classes.plotContainer}>
            <Plot
              data={[
                {
                  x: resampledData.map((item) => item[0]),
                  y: resampledData.map((item) => item[1]),
                  type: "bar",
                  marker: { color: "rgba(75,192,192,0.6)" },
                },
              ]}
              layout={getLayoutConfig()} // Use the new layout configuration function
              revision={revision}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GeneCountChart;
