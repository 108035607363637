import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { dimensions } from "../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
    backgroundColor: "#fff", // background color white
  },
  card: {
    backgroundColor: "white", // card background color white
    padding: theme.spacing(4),
    borderRadius: "8px", // rounded corners for the card
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // subtle shadow for depth
    width: dimensions.width / 4.2, // increased width for better layout
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(2), // increased spacing for a clean look
    },
    backgroundColor: "white", // form background color white
  },
  link: {
    color: "black", // link color gold
    "&:hover": {
      textDecoration: "underline", // underline on hover for links
    },
  },
  title: {
    color: "#000", // title color black
    textAlign: "center", // center the title
    marginBottom: theme.spacing(1), // margin below the title
  },
  input: {
    color: "#000", // input text color black
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FFD700", // underline color gold when not focused
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#FFD700", // underline color gold on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FFD700", // underline color gold when focused
    },
    width: dimensions.width / 7, // increased width for better layout
    height: dimensions.height / 25,
    margin: theme.spacing(1), // increased spacing for a clean look
  },
  visibilityIcon: {
    color: "black", // visibility icon color gold
  },
  loginStatusLabel: {
    color: "black", // login status label color gold
    textAlign: "center", // center the login status label
    marginTop: theme.spacing(2), // margin above the login status label
  },
  button: {
    marginTop: 1,
    width: "35%",
    color: "white",
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    "&:disabled": {
      backgroundColor: "white",
      color: "black",
    },
    marginLeft: "auto", // Add this line to center the button
    marginRight: "auto", // Add this line to center the button
  },
}));
export default function ResetPassword() {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordStatus, setPasswordStatus] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailToken, setEmailToken] = useState("");
  const navigate = useNavigate();

  const rules = [
    {
      exp: /[0-9]/,
      msg: "must contain at least one digit",
    },
    {
      exp: /[a-z]/,
      msg: "must contain at least one lowercase",
    },
    {
      exp: /[A-Z]/,
      msg: "must contain at least one uppercase",
    },
    {
      exp: /^.{6,20}$/,
      msg: "must be 6-20 characters long",
    },
  ];

  useEffect(() => {
    var i = 0;
    const pwShowHide = document.querySelectorAll(".eye-icon");
    pwShowHide.forEach((eyeIcon) => {
      if (i === 0) {
        eyeIcon.addEventListener("click", () => {
          let pwFields =
            eyeIcon.parentElement.parentElement.querySelectorAll(".password");
          pwFields.forEach((password) => {
            if (password.type === "password") {
              password.type = "text";
              eyeIcon.classList.replace("bx-hide", "bx-show");
              return;
            }
            password.type = "password";
            eyeIcon.classList.replace("bx-show", "bx-hide");
          });
        });
      } else if (i === 1) {
        eyeIcon.addEventListener("click", () => {
          let pwFields =
            eyeIcon.parentElement.parentElement.querySelectorAll(
              ".password_confirm",
            );
          pwFields.forEach((password) => {
            if (password.type === "password") {
              password.type = "text";
              eyeIcon.classList.replace("bx-hide", "bx-show");
              return;
            }
            password.type = "password";
            eyeIcon.classList.replace("bx-show", "bx-hide");
          });
        });
      }
      i += 1;
    });

    const queryParameters = new URLSearchParams(window.location.search);
    console.log("Query parameters: " + queryParameters);
    const token = queryParameters.get("token");
    setEmailToken(token);
  }, []);

  // axios.defaults.withCredentials = true;
  const resetPassword = async (event) => {
    event.preventDefault();

    var pass = true;
    var password_error = "";
    for (var i = 0; i < rules.length; i++) {
      var rule = rules[i];
      if (!rule.exp.test(password)) {
        pass = false;
        password_error = "Password " + rule.msg;
      }
    }

    if (password.indexOf(" ") !== -1) {
      setPasswordStatus("Password must contain no whitespaces");
    } else if (pass === false) {
      setPasswordStatus(password_error);
    } else if (emailToken !== "") {
      const email_data = {
        token: emailToken,
        password: password,
        confirmPassword: passwordConfirmation,
      };
      await axios.post("/reset_password", email_data).then((response) => {
        console.log("RESPONSE: ");
        console.log(response);
        if (response.data.message) {
          alert(response.data.message);
        } else {
          alert("Password was successfully changed");
          window.location.href = "/login";
        }
      });
    }
  };

  return (
    <body class="center-container">
      <section class="container forms">
        <div class="form">
          <div class="form-content">
            <header>Reset Password</header>
            <form action="/reset_password" onSubmit={resetPassword}>
              <div class="field input-field">
                <input
                  type="password"
                  placeholder="Password"
                  class="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i class="bx bx-hide eye-icon"></i>
              </div>

              <div class="field input-field">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  class="password_confirm"
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                <i class="bx bx-hide eye-icon"></i>
              </div>
              <div className="messageContainer">
                <p className="p-size">{passwordStatus}</p>
              </div>
              <div class="field button-field">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </body>
  );
}
