import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/home";
import AudioGene from "./Components/audioGene";
import Audioprofiles from "./Components/audioProfiles";
import About from "./Components/aboutUs";
import MyAccount from "./Components/myAccount";
import SurfaceViewer from "./Components/surfaceViewer";
import Workspaces from "./Components/workspaces";
import ViewAccounts from "./Components/viewAccounts";
import PasswordAccount from "./Utilities/forgotten_password";
import ResetPassword from "./Utilities/reset_password";
import CitingUs from "./Components/citingUs";
import ContactUs from "./Components/contactUs";
import Login from "./Utilities/log_in";
import Signup from "./Utilities/signup";
import AudioGeneWalkthrough from "./Components/audioGeneWalkThrough";
import Demo from "./Components/demo";

const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/audiogene" element={<AudioGene />} />
      <Route
        path="/audioGene_translational_dashboard"
        element={<AudioGeneWalkthrough />}
      />
      <Route
        path="/audioprofiles"
        element={<Audioprofiles usingWalkthrough={false} />}
      />
      <Route
        path="/audioprofile_surfaceviewer"
        element={<SurfaceViewer usingWalkthrough={false} />}
      />
      <Route path="/about" element={<About />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/contact_us" element={<ContactUs />} />
      <Route path="/citing_us" element={<CitingUs />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/my_account" element={<MyAccount />} />
      <Route path="/my_account/workspaces" element={<Workspaces />} />
      <Route path="/my_account/view_accounts" element={<ViewAccounts />} />
      <Route path="/forgotten_password_email" element={<PasswordAccount />} />
      <Route path="/reset_password" element={<ResetPassword />} />
    </Routes>
  );
};

export default AppRouter;
