// HypothesisFormulation.js
import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { dimensions } from "../constants";

const NUMERIC_FEATURES = [
  "125 dB",
  "250 dB",
  "500 dB",
  "1000 dB",
  "1500 dB",
  "2000 dB",
  "3000 dB",
  "4000 dB",
  "6000 dB",
  "8000 dB",
  "ethnicity",
];
const GENES = [
  "ACTG1",
  "CCDC50",
  "CEACAM16",
  "COCH",
  "COL11A2",
  "EYA4",
  "DIAPH1",
  "GJB2",
  "GRHL2",
  "GSDME",
  "KCNQ4",
  "MIRN96",
  "MYH14",
  "MYH9",
  "MYO6",
  "MYO7A",
  "P2RX2",
  "POU4F3",
  "REST",
  "SLC17A8",
  "TECTA",
  "TMC1",
  "WFS1",
];

const HypothesisFormulation = ({
  onNext,
  onBack,
  hypothesis,
  setHypothesis,
}) => {
  const handleNext = () => {
    setHypothesis({
      ...hypothesis,
      customModel: true,
    });
    onNext();
  };

  const handleSkip = () => {
    setHypothesis({ customModel: false });
    onNext();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <Card
        style={{
          width: dimensions.width / 1.5,
          boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
        }}
      >
        <CardContent>
          <Typography variant="h4" component="h2">
            Hypothesis Formulation Results
          </Typography>
          <Typography variant="body1" component="p" style={{ marginTop: 20 }}>
            This is where you can see the selected genes and resampling methods
            selected from the previous page for custom model creation.
          </Typography>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                Selected Genes:
              </Typography>
              <List>
                {hypothesis.selectedGenes.map((gene) => (
                  <ListItem key={gene}>
                    <ListItemText primary={gene} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                Resampling Methods Used:
              </Typography>
              <List>
                {["SMOTE", "NearMiss", "Random Sampling"].map((method) =>
                  hypothesis.resampling[method] ? (
                    <ListItem key={method}>
                      <ListItemText
                        primary={method}
                        secondary={`Threshold: ${method === "SMOTE" ? hypothesis.resampling["Min Threshold"] : method === "NearMiss" ? hypothesis.resampling["Max Threshold"] : hypothesis.resampling["Total Samples"]}`}
                      />
                    </ListItem>
                  ) : null,
                )}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                Gene Counts After Resampling:
              </Typography>
              <List>
                {Object.entries(hypothesis.resampling["Gene Counts"]).map(
                  ([gene, count]) => (
                    <ListItem key={gene}>
                      <ListItemText
                        primary={gene}
                        secondary={`Count: ${count}`}
                      />
                    </ListItem>
                  ),
                )}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Button onClick={onBack}>Back</Button>
              <Button onClick={handleNext}>Next</Button>
              <Button onClick={() => handleSkip()}>Skip</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HypothesisFormulation;
