import React, { useState, useEffect } from "react";
import Visualization from "./Visualization";
import HypothesisFormulation from "./HypothesisFormulation";
import CustomizeModel from "./CustomizeModel";
import MakePrediction from "./MakePrediction";
import Feedback from "./Feedback";
import ProcessAudiogramData from "./ProcessAudiogramData";
import UserAuthorization from "../Utilities/user_auth";
import { Box, CircularProgress } from "@material-ui/core";
import { AudioProfileProvider } from "../contexts/AudioProfileContext";
import { useAudioProfile } from "../contexts/AudioProfileContext";

const NUMERIC_FEATURES = [
  "125 dB",
  "250 dB",
  "500 dB",
  "1000 dB",
  "1500 dB",
  "2000 dB",
  "3000 dB",
  "4000 dB",
  "6000 dB",
  "8000 dB",
  "ethnicity",
];

const GENES = [
  "ACTG1",
  "CCDC50",
  "CEACAM16",
  "COCH",
  "COL11A2",
  "EYA4",
  "DIAPH1",
  "GJB2",
  "GRHL2",
  "GSDME",
  "KCNQ4",
  "MIRN96",
  "MYH14",
  "MYH9",
  "MYO6",
  "MYO7A",
  "P2RX2",
  "POU4F3",
  "REST",
  "SLC17A8",
  "TECTA",
  "TMC1",
  "WFS1",
];

const AudioGeneWalkthrough = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const [hypothesis, setHypothesis] = useState({
    selectedGenes: GENES,
    resampling: {},
    selectedParameters: {
      multiInstance: false,
      singleInstance: false,
    },
    customModel: false,
    allGeneData: [],
    audiogramPredicted: false,
    predictAudioProfiles: false,
    predictedData: [],
  });
  const [tableData, setTableData] = useState([]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div>
      {currentStep === 1 && (
        <Visualization
          onNext={nextStep}
          setTableData={setTableData}
          tableData={tableData}
          hypothesis={hypothesis}
          setHypothesis={setHypothesis}
          allGenes={GENES}
        />
      )}
      {currentStep === 2 && (
        <HypothesisFormulation
          onNext={nextStep}
          onBack={prevStep}
          hypothesis={hypothesis}
          setHypothesis={setHypothesis}
        />
      )}
      {/* {currentStep === 3 && (
        <CustomizeModel
          onNext={nextStep}
          onBack={prevStep}
          hypothesis={hypothesis}
        />
      )} */}
      {currentStep === 3 && (
        <MakePrediction
          onNext={nextStep}
          onBack={prevStep}
          hypothesis={hypothesis}
          data={tableData}
          customRequest={hypothesis.customModel}
          genes={hypothesis.selectedGenes}
          tableData={tableData}
          setHypothesis={setHypothesis}
        />
      )}
      {/* {currentStep === 4 && (
        <MakePrediction
          onNext={nextStep}
          onBack={prevStep}
          hypothesis={hypothesis}
        />
      )} */}
      {currentStep === 5 && <Feedback onBack={prevStep} />}
    </div>
  );
};

export default AudioGeneWalkthrough;
