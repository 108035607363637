import React, { useState } from "react";
import { Button, TextField, Container, Typography } from "@mui/material";
import emailValidator from "email-validator";
import axios from "axios";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  aboutBody: {
    padding: theme.spacing(4),
    backgroundColor: "white",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  aboutHeader: {
    marginTop: theme.spacing(2),
    color: "black",
    fontWeight: "bold",
    textAlign: "center",
  },
  aboutSubHeader: {
    marginTop: theme.spacing(2),
    color: "black",
    fontWeight: "bold",
    textAlign: "left",
  },
  form: {
    marginTop: theme.spacing(3),
    backgroundColor: "white",
  },
  textField: {
    marginBottom: theme.spacing(2),
    fontSize: "12px",
  },
  error: {
    color: "red",
  },
  textBody: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function ContactUs() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const classes = useStyles();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailValidator.validate(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setError(null);

    try {
      await axios.post("/send_email", {
        email,
        subject,
        message,
      });
      setEmail("");
      setSubject("");
      setMessage("");
      alert("Your message has been sent!");
    } catch (err) {
      setError(
        "An error occurred while sending your message. Please try again.",
      );
    }
  };

  return (
    <Container className={classes.aboutBody}>
      <Typography variant="h3" className={classes.aboutHeader}>
        Contact Us
      </Typography>
      <Typography variant="h5" className={classes.aboutSubHeader}>
        Questions and Comments:
      </Typography>
      <Typography variant="body1" className={classes.textBody}>
        This website is designed to inform and educate all types of users about
        AudioGene candidate gene prediction for autosomal dominant non-syndromic
        hearing loss (ADNSHL). We welcome questions and comments about
        AudioGene, the information on this site, or the site itself. We thank
        you in advance for your patience as we attempt to respond to all
        questions and comments in a timely manner.
      </Typography>
      <Typography variant="h5" className={classes.aboutSubHeader}>
        Help Make This Site Better:
      </Typography>
      <Typography variant="body1" className={classes.textBody}>
        Our goal is to provide you with the information that you are seeking. A
        large portion of time is devoted to providing visitors to this site with
        the most up-to-date site content related to the field of ADNSHL. In
        order to help us make this site better, please include your specific
        comments and suggestions for the parts of the site that you would like
        to see changed. In addition, it is helpful for us to know those parts of
        the website you liked. We look forward to your feedback to enable us to
        improve content and features.
      </Typography>
      <Typography variant="h5" className={classes.aboutHeader}>
        Submit a Comment:
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          required
          label="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
          className={classes.textField}
        />
        <TextField
          required
          label="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
          className={classes.textField}
        />
        <TextField
          required
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          rows={4}
          className={classes.textField}
        />
        {error && <p className={classes.error}>{error}</p>}
        <Button
          sx={{
            marginTop: 1,
            width: "15%",
            color: "white",
            backgroundColor: "black",
            "&:hover": {
              backgroundColor: "white",
              color: "black",
            },
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </form>
    </Container>
  );
}
