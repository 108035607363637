import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardHeader,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAudioProfile } from "../contexts/AudioProfileContext";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#white",
    color: "#FFD700",
    boxShadow: "0px 4px 20px 0px rgba(0,0,0,0.15)",
    padding: "20px",
    borderRadius: "15px",
    marginBottom: "20px",
    marginTop: "20px",
    width: "98%",
  },
  title: {
    color: "black",
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "20px",
    padding: "20px",
  },
  button: {
    backgroundColor: "white",
    color: "black",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
    "&:disabled": {
      backgroundColor: "#f7f7f7",
    },
    marginTop: "20px",
  },
  tableCell: {
    // Define styles for the TableCell
    fontSize: "1.15rem", // Increase font size
    textAlign: "center", // Center text horizontally
  },
});

const SubmittedDataTable = ({ handlePredictClick }) => {
  const { tableData } = useAudioProfile();
  const classes = useStyles();

  // Sort tableData by ID first, then by age within each ID
  const sortedTableData = [...tableData].sort((a, b) => {
    if (a.id === b.id) {
      // If IDs are the same, sort by age
      return a.age - b.age;
    }
    // Convert IDs to numbers if they are numeric, otherwise compare as strings
    return a.id
      .toString()
      .localeCompare(b.id.toString(), undefined, { numeric: true });
  });

  const determineModelVersions = (tableData) => {
    const idCounts = tableData.reduce((acc, curr) => {
      acc[curr.id] = (acc[curr.id] || 0) + 1;
      return acc;
    }, {});

    return tableData.map((row) => ({
      ...row,
      modelVersion: idCounts[row.id] > 1 ? "AudioGene V4" : "AudioGene V9.1",
    }));
  };

  const dataWithModelVersions = determineModelVersions(sortedTableData);

  console.log("sortedTableData: ", sortedTableData);

  return (
    <Card className={classes.card}>
      <CardHeader title="Submitted Data" className={classes.title} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>ID</TableCell>
              <TableCell className={classes.tableCell}>Age</TableCell>
              <TableCell className={classes.tableCell}>Ear</TableCell>
              <TableCell className={classes.tableCell}>125 Hz</TableCell>
              <TableCell className={classes.tableCell}>250 Hz</TableCell>
              <TableCell className={classes.tableCell}>500 Hz</TableCell>
              <TableCell className={classes.tableCell}>1000 Hz</TableCell>
              <TableCell className={classes.tableCell}>1500 Hz</TableCell>
              <TableCell className={classes.tableCell}>2000 Hz</TableCell>
              <TableCell className={classes.tableCell}>3000 Hz</TableCell>
              <TableCell className={classes.tableCell}>4000 Hz</TableCell>
              <TableCell className={classes.tableCell}>6000 Hz</TableCell>
              <TableCell className={classes.tableCell}>8000 Hz</TableCell>
              <TableCell className={classes.tableCell}>Model Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataWithModelVersions.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableCell}> {row.id} </TableCell>
                <TableCell className={classes.tableCell}> {row.age} </TableCell>
                <TableCell className={classes.tableCell}> {row.ear} </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["125 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["250 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["500 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["1000 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["1500 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["2000 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["3000 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["4000 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["6000 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {" "}
                  {row["8000 dB"]}{" "}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.modelVersion}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => handlePredictClick()}
      >
        Predict Audio Profiles
      </Button>
    </Card>
  );
};

export default SubmittedDataTable;
