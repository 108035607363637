import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardHeader,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { dimensions } from "../constants";
import { set } from "react-ga";
import { useAudioProfile } from "../contexts/AudioProfileContext";

const useStyles = makeStyles({
  card: {
    borderRadius: "15px",
    width: "100%",
    marginBottom: "20px",
  },
  header: {
    fontWeight: "bold",
  },
  table: {
    overflow: "scroll",
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f7f7f7", // light grey for odd rows
    },
  },
  tableCell: {
    fontWeight: "bold", // make text bold
    padding: "8px", // increase padding for more room
    color: "#000000", // black text color
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontStyle: "italic",
  },
  tableHeader: {
    fontWeight: "bold", // make header text bold
    backgroundColor: "#e0e0e0", // different background for headers
    color: "#000000", // black text color
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  button: {
    margin: "16px",
    color: "#FFFFFF",
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#000000",
    },
    "&:disabled": {
      backgroundColor: "#f7f7f7",
    },
  },
  container: {
    width: "98%",
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const ProcessAudiogramData = ({
  customRequest,
  genes,
  predictAudioProfilesCounter,
  setMetrics,
  setPredictionMade,
  usingWalkthrough,
  setAudiogramPredicted,
}) => {
  const classes = useStyles();
  const [outputData, setOutputData] = useState({
    audiogenev9: [],
    audiogenev4: [],
  });
  const {
    setPredictionData,
    isPredicted,
    predictionData,
    saveIsPredicted,
    tableData,
    resamplingData,
  } = useAudioProfile();

  const loadExcel = () => {
    // Define the headers for page1 and page2 as in audioGene.js
    const page1Header = {
      ID: "(No confidential info)",
      "Date of Birth": " ",
      Sex: "(M/F/Unknown)",
      "Age of Onset": "(Years)",
      "Number of affected family": " ",
      "Data Source": " ",
      "Known Mutation": "Locus",
      "Known Mutation1": "Gene",
      Citation: "(Pubmed_id)",
    };

    const page2Header = {
      Patient: "(enter on patient form first)",
      Age: "(Years)",
      Ear: "(L/R/Better)",
      "dB of loss at:": "125 Hz",
      "dB of loss at:1": "250 Hz",
      "dB of loss at:2": "500 Hz",
      "dB of loss at:3": "1000 Hz",
      "dB of loss at:4": "1500 Hz",
      "dB of loss at:5": "2000 Hz",
      "dB of loss at:6": "3000 Hz",
      "dB of loss at:7": "4000 Hz",
      "dB of loss at:8": "6000 Hz",
      "dB of loss at:9": "8000 Hz",
    };

    const uniqueIds = new Set(tableData.map((entry) => entry.id));

    // Start page1XLS and page2XLS with the headers
    const page1XLS = Array.from(uniqueIds).map((id) => {
      return {
        ID: id,
        "Date of Birth": " ",
        Sex: "",
        "Age of Onset": "",
        "Number of affected family": "",
        "Data Source": "",
        "Known Mutation": "",
        "Known Mutation1": "",
        Citation: " ",
      };
    });
    page1XLS.unshift(page1Header);
    // Transform the data for page2
    const page2XLS = [
      page2Header,
      ...tableData.map((entry) => {
        let newId = entry.id;
        // Check if the ID contains "ID" or "Id" or "id", if not add it
        if (!newId.toLowerCase().includes("id")) {
          newId = "ID " + newId;
        }

        return {
          Patient: newId,
          Age: Number(entry.age),
          Ear: entry.earSide,
          "dB of loss at:": Number(entry["125 dB"]),
          "dB of loss at:1": Number(entry["250 dB"]),
          "dB of loss at:2": Number(entry["500 dB"]),
          "dB of loss at:3": Number(entry["1000 dB"]),
          "dB of loss at:4": Number(entry["1500 dB"]),
          "dB of loss at:5": Number(entry["2000 dB"]),
          "dB of loss at:6": Number(entry["3000 dB"]),
          "dB of loss at:7": Number(entry["4000 dB"]),
          "dB of loss at:8": Number(entry["6000 dB"]),
          "dB of loss at:9": Number(entry["8000 dB"]),
        };
      }),
    ];

    // Determine the audiogene version based on some condition, for example, the length of the input data
    const audiogeneV = tableData.length > 1 ? "audiogenev4" : "audiogenev9";

    console.log("resamplingData.resample", resamplingData.resample);

    // Return the transformed data
    return {
      page1XLS,
      page2XLS,
      audiogeneCall: audiogeneV,
      customRequest,
      genes,
      resample: resamplingData.resample,
    };
  };

  const execCommand = useCallback(async () => {
    try {
      console.log("Predicting audio profiles, isPredicted:", isPredicted);
      const data = loadExcel();
      console.log("Data for prediction:", data);
      const response = await axios.post("/bashscript", data);
      console.log("Response for prediction:", response);
      const model_versions = customRequest
        ? ["audiogenev9"]
        : ["audiogenev9", "audiogenev4"];
      const newOutputData = {};

      console.log("Response data for prediction:", response.data);

      model_versions.forEach((version) => {
        if (response.data.data[`${version}_data`] !== undefined) {
          newOutputData[version] = transformData(
            response.data.data[`${version}_data`],
            response.data.data[`${version}_keys`],
            version,
          );
          if (customRequest && version === "audiogenev9") {
            if (response.data.data[`${version}_metrics`]) {
              const metrics = response.data.data[`${version}_metrics`];
              const metrics_dict = {
                accuracy: metrics.accuracy,
                recall: metrics.recall,
                top3Accuracy: metrics.top_3_accuracy,
                unweightedAccuracy: metrics.unweighted_accuracy,
                auc: metrics.auc,
              };
              console.log("Metrics:", metrics_dict);
              setMetrics(metrics_dict);
            }
          }
        }
      });

      setOutputData(newOutputData);
      setPredictionData(newOutputData);
      console.log("Output Data:", newOutputData);
      saveIsPredicted(true);
      if (!usingWalkthrough) {
        setAudiogramPredicted(true);
      }
      console.log("after saving isPredicted:", isPredicted);
    } catch (error) {
      console.error("Error:", error);
    }
  }, [tableData, setOutputData, customRequest]);

  const geneConversionMap = {
    DFNA1: "DIAPH1",
    DFNA2A: "KCNQ4",
    DFNA3A: "GJB2",
    DFNA4A: "MYH14",
    DFNA4B: "CEACAM16",
    DFNA5: "GSDME",
    "DFNA6/14/38": "WFS1",
    "DFNA6/14": "WFS1",
    "DFNA6/14/38/14/38": "WFS1",
    "DFNA8/12": "TECTA",
    DFNA9: "COCH",
    DFNA10: "EYA4",
    DFNA11: "MYO7A",
    DFNA13: "COL11A2",
    DFNA15: "POU4F3",
    DFNA17: "MYH9",
    "DFNA20/26": "ACTG1",
    DFNA22: "MYO6",
    DFNA25: "SLC17A8",
    DFNA27: "REST",
    DFNA28: "GRHL2",
    DFNA36A: "TMC1",
    DFNA41: "P2RX2",
    DFNA44: "CCDC50",
    DFNA50: "MIRN96",
  };

  const transformData = (fullMap, keysAsArray, version) => {
    const transformedData = keysAsArray
      .map((key) => {
        let rowData = fullMap[key];
        if (rowData && typeof rowData === "object" && !Array.isArray(rowData)) {
          rowData = Object.values(rowData);
        }
        if (version === "audiogenev9") {
          return {
            id: key,
            genes: rowData.slice(0),
          };
        } else if (version === "audiogenev4") {
          const id = rowData[0];
          let genes = rowData.slice(1);
          let unusedGenes = Object.keys(geneConversionMap);
          // Convert gene names using the geneConversionMap, if not in the map discard the gene
          genes = genes
            .map((name) => {
              const convertedName = geneConversionMap[name];
              // Remove the used gene from the unusedGenes list
              unusedGenes = unusedGenes.filter((gene) => gene !== name);
              return convertedName;
            })
            .filter(Boolean); // Filter out any undefined values (genes not found in the map)
          // Limit the number of genes to 23
          genes = genes.slice(0, 23);
          return {
            id: id,
            genes: genes,
          };
        }
      })
      .filter((row) => row) // Filter out any undefined rows
      .sort((a, b) => {
        // Extract the numeric part of the ID
        const idANumber = parseInt(a.id.replace(/^\D+/g, ""), 10);
        const idBNumber = parseInt(b.id.replace(/^\D+/g, ""), 10);

        // Compare the numeric parts
        return idANumber - idBNumber;
      });

    return transformedData;
  };

  // Call execCommand when the component mounts
  useEffect(() => {
    if (!isPredicted || !usingWalkthrough) {
      console.log("Predicting audio profiles");
      execCommand();
    } else {
      console.log("Audio profiles already predicted");
      setOutputData(predictionData);
    }
  }, [execCommand, predictAudioProfilesCounter]); // The dependency array includes execCommand to ensure the effect runs when the function changes

  return (
    <div className={classes.container}>
      {outputData.audiogenev9 && outputData.audiogenev9.length > 0 && (
        <OutputTable data={outputData.audiogenev9} version="audiogenev9" />
      )}
      {outputData.audiogenev4 && outputData.audiogenev4.length > 0 && (
        <OutputTable data={outputData.audiogenev4} version="audiogenev4" />
      )}
    </div>
  );
};

const OutputTable = ({ data, version, onCellClick, customRequest }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <Typography variant="h6">
            {customRequest && version === "audiogenev9"
              ? "Custom AG9 Results"
              : `Output Data - ${version.toUpperCase()}`}
          </Typography>
        }
        className={classes.header}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>ID</TableCell>
              {data[0].genes.map((gene, geneIndex) => (
                <TableCell key={geneIndex} className={classes.tableHeader}>
                  Gene {geneIndex + 1}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              // Apply the tableRow class to each TableRow within TableBody
              <TableRow key={index} className={classes.tableRow}>
                {/* Apply the tableCell class to each TableCell within TableRow */}
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCell}
                >
                  {row.id}
                </TableCell>
                {row.genes.map((gene, geneIndex) => (
                  <TableCell key={geneIndex} className={classes.tableCell}>
                    {gene}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default ProcessAudiogramData;
