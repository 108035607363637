import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Card,
  CardContent,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import ProcessAudiogramData from "./ProcessAudiogramData";
import { useAudioProfile } from "../contexts/AudioProfileContext";

const MakePrediction = ({ onNext, onBack, hypothesis, setHypothesis }) => {
  const [loading, setLoading] = useState(false);
  const [makePrediction, setMakePrediction] = useState(false);
  const [predictAudioProfilesCounter, setPredictAudioProfilesCounter] =
    useState(0);
  const [metrics, setMetrics] = useState({
    accuracy: null,
    recall: null,
    top3Accuracy: null,
    unweightedAccuracy: null,
    auc: null,
  });
  const {
    isPredicted,
    setIsPredicted,
    predictionData,
    savePredictionData,
    tableData,
  } = useAudioProfile();

  // Function to handle the Predict button click
  const handlePredictClick = () => {
    setIsPredicted(false);
    setMakePrediction(true);
    setLoading(true);
    setPredictAudioProfilesCounter(predictAudioProfilesCounter + 1);
    // You might want to initiate the prediction process here or reset relevant state
  };

  useEffect(() => {
    console.log("isPredicted: ", isPredicted);
    if (isPredicted) {
      savePredictionData(predictionData);
      setLoading(false);
      setMakePrediction(true);
    }
  }, [isPredicted]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={2}
      marginBottom={2}
    >
      <Typography variant="h4">Customized Model predictions</Typography>
      {makePrediction && (
        <>
          <ProcessAudiogramData
            data={tableData}
            customRequest={true}
            genes={hypothesis.selectedGenes}
            setHypothesis={setHypothesis}
            setMetrics={setMetrics}
            predictAudioProfilesCounter={predictAudioProfilesCounter}
            usingWalkthrough={true}
          />
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          )}
          {metrics.accuracy && (
            <>
              <Card
                style={{
                  width: "75%",
                  boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
                }}
              >
                <CardContent>
                  <>
                    <Typography variant="h6">Metrics:</Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Accuracy</TableCell>
                            <TableCell>Recall</TableCell>
                            <TableCell>Top 3 Accuracy</TableCell>
                            <TableCell>Unweighted Accuracy</TableCell>
                            <TableCell>AUC</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{metrics.accuracy}</TableCell>
                            <TableCell>{metrics.recall}</TableCell>
                            <TableCell>{metrics.top3Accuracy}</TableCell>
                            <TableCell>{metrics.unweightedAccuracy}</TableCell>
                            <TableCell>{metrics.auc}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Typography variant="h6">Prediction:</Typography>
                  </>
                </CardContent>
              </Card>
            </>
          )}
        </>
      )}
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button onClick={onBack}>Back</Button>
        <Button onClick={handlePredictClick}>Predict</Button>
        <Button onClick={onNext}>Next</Button>
      </Box>
    </Box>
  );
};

export default MakePrediction;
