import axios from "axios";
import TokenService from "./token_service";

// Before making request, do the following
axios.interceptors.request.use(
  (config) => {
    const token = TokenService.getAccessToken();
    config.headers["x-access-token"] = token;
    const admin_token = TokenService.getAccessAdminToken();
    config.headers["x-access-admin-token"] = admin_token;
    const email = TokenService.getUserEmail();
    config.headers["x-access-email"] = email;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // access token expired
      if (err.response.status === 403 && !originalConfig._retry) {
        // handle infinite loop
        originalConfig._retry = true;

        try {
          const rs = await axios.post("/refresh_token", {
            refreshToken: TokenService.getRefreshToken(),
          });
          if (rs.data.errors) {
            TokenService.removeUser();
            return axios(originalConfig);
          } else {
            const accessToken = rs.data.access_token;
            TokenService.updateNewAccessToken(accessToken);
            if (rs.data.access_admin_token) {
              const accessAdminToken = rs.data.access_admin_token;
              TokenService.updateNewAccessAdminToken(accessAdminToken);
            }
            return axios(originalConfig);
          }
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);

export default axios;
