import "./myAccount.css";
import React, { useState, useEffect, useLayoutEffect } from "react";
import UserAuthorization from "../Utilities/user_auth";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import TokenService from "../Utilities/token_service";

export default function ViewAccount() {
  const [userInfo, setUserInfo] = useState({});

  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userAssociation, setUserAssociation] = useState("");
  const [userAdmin, setUserAdmin] = useState(false);

  const [accountRows, setAccountRows] = useState([]);
  const [workspaceRows, setWorkspaceRows] = useState([]);

  const [sort, setSort] = useState("");
  const [accountSort, setAccountSort] = useState("");
  const [workspaceSort, setWorkspaceSort] = useState("");
  const [approverList, setApproverList] = useState([]);
  const [approverEmail, setApproverEmail] = useState("");
  const [approverStatus, setApproverStatus] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    (async () => {
      const user_info = await UserAuthorization();
      setUserInfo(user_info);
      setUserEmail(user_info.user_email);
      setUserFirstName(user_info.user_first_name);
      setUserLastName(user_info.user_last_name);
      setUserAssociation(user_info.user_association);
      setUserAdmin(user_info.user_admin);
      setAccountSort(document.getElementById("account-select").value);
      setWorkspaceSort(document.getElementById("workspace-select").value);
      const approvers = await getApprovers();
      document.getElementById("view-account-button").onclick = getUserAccounts;
      document.getElementById("view-workspace-button").onclick =
        getUserWorkspaces;
    })();
  }, [
    userEmail,
    userFirstName,
    userLastName,
    userAssociation,
    userAdmin,
    accountSort,
    workspaceSort,
  ]);

  // button themes
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FFCD00",
        hover: "black",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "black",
            marginBottom: "10px",
            width: "400px",
          },
        },
        containedPrimary: {
          backgroundColor: (theme) => theme.palette.primary.main,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.hover,
          },
        },
      },
    },
  });

  const theme2 = createTheme({
    palette: {
      primary: {
        main: "#FFCD00",
        hover: "black",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: "black",
            marginBottom: "10px",
            fontSize: "10pt",
            width: "200px",
          },
        },
        containedPrimary: {
          backgroundColor: (theme) => theme.palette.primary.main,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.hover,
          },
        },
      },
    },
  });

  // timeout function for the
  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  // gets all user accounts from the database, sets an array of html elements
  // that also contain the following functions on each account returned:
  // get workspaces, change password, deactivate/activate account, delete account
  const getUserAccounts = async () => {
    if (userAdmin === 1) {
      var data = {};
      if (accountSort === "Date") {
        data = {
          admin: userAdmin,
          sort: "users.timestamp",
        };
      } else if (accountSort === "Name") {
        data = {
          admin: userAdmin,
          sort: "users.email",
        };
      }
      var account_rows = [];
      var user_response = [];
      var workspace_count = 0;
      var account_activated = false;
      await axios.post("/get_all_users", data).then((response) => {
        user_response = response;
        for (var i = 0; i < user_response.data.length; i++) {
          workspace_count = user_response.data[i].workspaces;
          account_activated = user_response.data[i].verified_access;
          var role = "Guest User";
          if (user_response.data[i].approver === 1) {
            role = "Approver";
          } else if (user_response.data[i].verified_access === 1) {
            role = "Researcher";
          }
          account_rows.push(
            <div class="row-container">
              <div class="title-container">
                <p class="account-title">Account Email: </p>
              </div>
              <div class="title-container">
                <p> {user_response.data[i].email} </p>
              </div>
              <br></br>
              <div class="title-container2">
                <p class="account-title2"> Date Created: </p>
                <p> {user_response.data[i].timestamp.slice(0, 10)} </p>
              </div>
              <div class="title-container2">
                <p class="account-title2">Number of Workspaces: </p>
                <p> {workspace_count} </p>
              </div>
              <div class="title-container2">
                <p class="account-title2">Approved by: </p>
                <p> {user_response.data[i].association} </p>
              </div>
              <div class="title-container2">
                <p class="account-title2">Role: </p>
                <p> {role} </p>
              </div>
              <br></br>
              <br></br>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  style={{ cursor: "pointer", fontSize: 14 }}
                  id={user_response.data[i].email}
                  onClick={(e) => changePassword(e)}
                >
                  Change this account's password
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Button
                  variant="contained"
                  style={{ cursor: "pointer", fontSize: 14 }}
                  id={user_response.data[i].id}
                  name={user_response.data[i].email}
                  onClick={(e) => viewCertainAccountWorkspaces(e)}
                >
                  View this account's workspaces
                </Button>
              </ThemeProvider>
              {!!account_activated && (
                <ThemeProvider theme={theme2}>
                  <Button
                    variant="contained"
                    style={{ cursor: "pointer", fontSize: 14 }}
                    id={user_response.data[i].id}
                    onClick={(e) => deactivateAccount(e)}
                  >
                    Deactivate Account
                  </Button>
                </ThemeProvider>
              )}
              {!account_activated && (
                <ThemeProvider theme={theme2}>
                  <Button
                    variant="contained"
                    style={{ cursor: "pointer", fontSize: 14 }}
                    id={user_response.data[i].id}
                    onClick={(e) => activateAccount(e)}
                  >
                    Activate Account
                  </Button>
                </ThemeProvider>
              )}
              <ThemeProvider theme={theme2}>
                <Button
                  variant="contained"
                  style={{ cursor: "pointer", fontSize: 14 }}
                  id={user_response.data[i].id}
                  onClick={(e) => deleteAccount(e)}
                >
                  Delete Account
                </Button>
              </ThemeProvider>
            </div>,
          );
        }
        setAccountRows(account_rows);
        document.getElementById("view-account-button").innerHTML =
          "Unview all accounts";
        document.getElementById("view-account-button").onclick = resetView;
      });
    }
  };

  // gets all user workspaces from the database, and will return
  // an array of html elements that have the buttons load workspace, which
  // is attached to the load workspace function and then delete workspace
  // which is attached to the delete workspace button
  const getUserWorkspaces = async () => {
    if (userAdmin === 1) {
      var workspace_rows = [];
      var workspace_response = [];
      var number_of_audiograms = 0;
      var account_email = "";
      var data = {};
      if (workspaceSort === "Date") {
        data = {
          admin: userAdmin,
          sort: "workspaces.timestamp",
        };
      } else if (workspaceSort === "Name") {
        data = {
          admin: userAdmin,
          sort: "workspaces.name",
        };
      }
      await axios.post("/get_all_workspaces", data).then((response) => {
        workspace_response = response;
      });
      for (var i = 0; i < workspace_response.data.length; i++) {
        var workspace_id = workspace_response.data[i].id;
        number_of_audiograms = workspace_response.data[i].audiograms;
        account_email = workspace_response.data[i].email;
        workspace_rows.push(
          <div class="row-container">
            <div class="title-container">
              <p class="account-title">Workspace Name: </p>
            </div>
            <div class="title-container">
              <p> {workspace_response.data[i].name} </p>
            </div>
            <br></br>
            <div class="title-container2">
              <p class="account-title2"> Date Created: </p>
              <p> {workspace_response.data[i].timestamp.slice(0, 10)} </p>
            </div>
            <div class="title-container2">
              <p class="account-title2">Number of Audiograms: </p>
              <p> {number_of_audiograms} </p>
            </div>
            <div class="title-container2">
              <p class="account-title2">Belongs to: </p>
              <p> {account_email} </p>
            </div>
            <br></br>
            <br></br>
            <ThemeProvider theme={theme2}>
              <Button
                variant="contained"
                style={{ cursor: "pointer", fontSize: 14 }}
                id={workspace_id}
                onClick={(e) => loadWorkspace(e)}
              >
                Load Workspace
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={theme2}>
              <Button
                variant="contained"
                style={{ cursor: "pointer", fontSize: 14 }}
                id={workspace_id}
                onClick={(e) => deleteWorkspace(e)}
              >
                Delete Workspace
              </Button>
            </ThemeProvider>
          </div>,
        );
      }
      document.getElementById("view-workspace-button").innerHTML =
        "Unview all workspaces";
      document.getElementById("view-workspace-button").onclick =
        resetWorkspaceView;
      setWorkspaceRows(workspace_rows);
    }
  };

  // this function will remove all the html elements being displayed after clicking view
  // all accounts, it will run when the admin either clicks 'unview all accounts' or changes an
  // option in the dropdown menu
  const resetView = () => {
    setAccountRows([]);
    document.getElementById("view-account-button").innerHTML =
      "View all accounts";
    document.getElementById("view-account-button").onclick = getUserAccounts;
  };

  // same function as the one above except it is for the workspace html elements
  const resetWorkspaceView = () => {
    setWorkspaceRows([]);
    document.getElementById("view-workspace-button").innerHTML =
      "View all workspaces";
    document.getElementById("view-workspace-button").onclick =
      getUserWorkspaces;
  };

  // corresponds to the 'delete account' button on the user html elements, has a popup appear to confirm before
  // deleting an account, and then calls the backend to then delete the account, then reloads the html elements
  const deleteAccount = async (e) => {
    // comfirm window will appear and ask if its okay to delete this account?
    const id = e.target.id;
    if (window.confirm("Are you sure you would like to delete this account?")) {
      await axios.post("/delete_account", { id: id }).then((response) => {
        getUserAccounts();
      });
    }
  };

  // this sets the account to be a 'Researcher' account, allowing them to use workspaces
  // their association will change to be 'Richard Smith' no matter who they selected as their approver
  const activateAccount = async (e) => {
    const id = e.target.id;
    if (
      window.confirm("Are you sure you would like to activate this account?")
    ) {
      await axios.post("/activate_account", { id: id }).then((response) => {
        getUserAccounts();
      });
    }
  };

  // instead of allowing an account to use workspaces, this account disallows users from using the
  // workspaces.
  const deactivateAccount = async (e) => {
    const id = e.target.id;
    if (
      window.confirm("Are you sure you would like to deactivate this account?")
    ) {
      await axios.post("/deactivate_account", { id: id }).then((response) => {
        getUserAccounts();
      });
    }
  };

  // corresponds to the 'delete workspace' button on the html elements for the workspaces
  // will open a popup for the admin to confirm that they are sure they would like to delete the workspace
  const deleteWorkspace = async (e) => {
    const id = e.target.id;
    if (
      window.confirm("Are you sure you would like to delete this workspace?")
    ) {
      await axios.post("/delete_workspace", { id: id }).then((response) => {
        getUserWorkspaces();
      });
    }
  };

  // this function opens a new tab with the corresponding workspace that is clicked on
  // and by setting the workspace id in local storage it will then be loaded on the
  // audiogene page when it is opened
  const loadWorkspace = async (e) => {
    const id = e.target.id;
    localStorage.setItem("workspace_id", id);
    const url = window.location.protocol + "/audiogene";
    window.open(url, "_blank");
  };

  // this is one of the buttons on the user account html elements, and will open up
  // a new page and allow the admin to set a new password for the user. If this were to
  // happen for the wrong account accidentally, the user will always have the option to
  // change their password on the login page
  const changePassword = async (e) => {
    var email_token = "";
    var email = e.target.id;
    await axios
      .post("/create_email_token", { email: email })
      .then((response) => {
        email_token = response.data.token;
      });
    const url =
      window.location.protocol + `/password_confirmation/${email_token}`;
    window.open(url, "_blank");
  };

  // corresponds to the 'view this account's workspaces' button for the user's html elements.
  // this loads the workspace rows with just workspaces corresponding to the selected user,
  // and will also be encased in a black border instead of a yellow one, but the workspaces
  // will still have the same options being load and delete.
  const viewCertainAccountWorkspaces = async (e) => {
    const id = e.target.id;
    const email = e.target.name;
    if (userAdmin === 1) {
      var workspace_response = [];
      var data = {};
      var workspace_rows = [];
      var number_of_audiograms = 0;
      var workspace_sort = document.getElementById("workspace-select").value;
      if (workspace_sort === "Date") {
        data = {
          id: id,
          sort: "workspaces.timestamp",
        };
        await axios.post("/find_workspaces", data).then((response) => {
          workspace_response = response;
        });
      } else if (workspace_sort === "Name") {
        data = {
          id: id,
          sort: "workspaces.name",
        };
        await axios.post("/find_workspaces", data).then((response) => {
          workspace_response = response;
        });
      }
      for (var i = 0; i < workspace_response.data.length; i++) {
        var workspace_id = workspace_response.data[i].id;
        number_of_audiograms = workspace_response.data[i].audiograms;
        workspace_rows.push(
          <div class="row-container2">
            <div class="title-container">
              <p class="account-title">Workspace Name: </p>
            </div>
            <div class="title-container">
              <p> {workspace_response.data[i].name} </p>
            </div>
            <br></br>
            <div class="title-container2">
              <p class="account-title2"> Date Created: </p>
              <p> {workspace_response.data[i].timestamp.slice(0, 10)} </p>
            </div>
            <div class="title-container2">
              <p class="account-title2">Number of Audiograms: </p>
              <p> {number_of_audiograms} </p>
            </div>
            <br></br>
            <br></br>
            <ThemeProvider theme={theme2}>
              <Button
                variant="contained"
                style={{ cursor: "pointer", fontSize: 14 }}
                id={workspace_id}
                onClick={(e) => loadWorkspace(e)}
              >
                Load Workspace
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={theme2}>
              <Button
                variant="contained"
                style={{ cursor: "pointer", fontSize: 14 }}
                id={workspace_id}
                onClick={(e) => deleteWorkspace(e)}
              >
                Delete Workspace
              </Button>
            </ThemeProvider>
          </div>,
        );
      }
      document.getElementById("view-workspace-button").innerHTML =
        "Unview " + email + "'s workspaces";
      document.getElementById("view-workspace-button").onclick =
        resetWorkspaceView;
      setWorkspaceRows(workspace_rows);
    }
  };

  // sets the variable of the sorted workspace variable to be the current value in the dropdown
  const setSortWorkspaces = async (sortMethod) => {
    setWorkspaceSort(document.getElementById("workspace-select").value);
    setWorkspaceRows([]);
    document.getElementById("view-workspace-button").innerHTML =
      "View all workspaces";
    document.getElementById("view-workspace-button").onclick =
      getUserWorkspaces;
  };

  // sets the variable of the sorted account variable to be the current value in the dropdown
  const setSortAccounts = async (sortMethod) => {
    setAccountSort(document.getElementById("account-select").value);
    setAccountRows([]);
    document.getElementById("view-account-button").innerHTML =
      "View all accounts";
    document.getElementById("view-account-button").onclick = getUserAccounts;
  };

  // gets all approver accounts in the database and displays them at the top
  const getApprovers = async () => {
    var approvers = [];
    await axios.post("/get_all_approvers", {}).then((response) => {
      for (var i = 0; i < response.data.length; i++) {
        approvers.push(
          <div className="row-container3">
            <div class="title-container3">
              <p class="account-title2">Name: </p>
              <p>
                {" "}
                {response.data[i].first_name +
                  " " +
                  response.data[i].last_name}{" "}
              </p>
            </div>
            <div class="title-container3">
              <p class="account-title2">Email: </p>
              <p> {response.data[i].email} </p>
            </div>
            <br></br>
            <br></br>
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                style={{ cursor: "pointer", fontSize: 14 }}
                id={response.data[i].email}
                onClick={(e) => removeApprover(e)}
              >
                Remove Approver
              </Button>
            </ThemeProvider>
          </div>,
        );
      }
      setApproverList(approvers);
    });

    setApproverList(approvers);
  };

  // remove an account from the approvers function
  const removeApprover = async (e) => {
    var user_email = e.target.id;
    await axios
      .post("/remove_approver", { email: user_email })
      .then((response) => {
        getApprovers();
      });
  };

  // add an approver by getting the email from the input field and checking if it is a valid
  // email with a backend function. When it returns we set the response to display for 2 seconds
  const addApprover = async () => {
    var user_email = approverEmail;
    var response_message = "";
    await axios
      .post("/add_approver", { email: user_email })
      .then((response) => {
        if (response.data.message) {
          response_message = response.data.message;
        }
      });

    setApproverStatus(response_message);
    await timeout(2000);
    setApproverStatus("");
    getApprovers();
  };

  return (
    <body class="myAccount-body">
      <div class="audiogene-header">User Account Information</div>
      <br></br>

      {userAdmin && (
        <div className="center-block">
          <div class="button-container">
            <h2>Approvers: </h2>
          </div>
          <div class="button-container">{approverList}</div>
          <br></br>
          <div class="button-container">
            Add Approver With Email:
            <input
              className="input"
              type="text"
              placeholder="Email"
              onChange={(e) => setApproverEmail(e.target.value)}
            ></input>
            <button className="button-add" onClick={() => addApprover()}>
              Add
            </button>
          </div>
          <div className="red">{approverStatus}</div>
          <br></br>
          <br></br>
          <hr className="separator" />
          <br></br>
          <div class="button-container">
            <h2>Accounts: </h2>
          </div>
          <div class="button-container">
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                id="view-account-button"
                style={{ cursor: "pointer", fontSize: 14 }}
              >
                View All Accounts
              </Button>
            </ThemeProvider>
          </div>
          <div class="sort-container">
            <p> Sort Accounts: </p>
            <select
              id="account-select"
              class="dropdown-select"
              onChange={(e) => setSortAccounts(e.target.value)}
            >
              <option value="Date">Date Created</option>
              <option value="Name">Email A-Z</option>
            </select>
          </div>
          <br></br>
          <div class="user-account-rows">{accountRows}</div>
          <br></br>
          <hr className="separator" />
          <br></br>
          <div class="button-container">
            <h2>Workspaces: </h2>
          </div>
          <div class="button-container">
            <ThemeProvider theme={theme}>
              <Button
                variant="contained"
                id="view-workspace-button"
                style={{ cursor: "pointer", fontSize: 14 }}
              >
                View All Workspaces
              </Button>
            </ThemeProvider>
          </div>
          <div class="sort-container">
            <p> Sort Workspaces: </p>
            <select
              id="workspace-select"
              class="dropdown-select"
              onChange={(e) => setSortWorkspaces(e.currentTarget.value)}
            >
              <option value="Date">Date Created</option>
              <option value="Name">Name A-Z</option>
            </select>
          </div>
          <br></br>
          <div class="user-account-rows">{workspaceRows}</div>
          <br></br>
        </div>
      )}
    </body>
  );
}
