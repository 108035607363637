import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "./api";
import TokenService from "./token_service";

var userId = 0;
var userEmail = "";
var userFirstName = "";
var userLastName = "";
var userAssociation = "";
var userAuth = false;
var userEmailValidation = false;
var userAssociatorValidation = false;
var userAdmin = false;
var userAssociator = false;
var loggedIn = false;

const UserAuthorization = async () => {
  await api.get("/check_login").then((response) => {
    if (response.data.loggedIn === true) {
      loggedIn = response.data.loggedIn;
      userId = response.data.user[0].id;
      userEmail = response.data.user[0].email;
      userFirstName = response.data.user[0].first_name;
      userLastName = response.data.user[0].last_name;
      userAssociation = response.data.user[0].association;
      userEmailValidation = response.data.user[0].verified_email;
      userAssociatorValidation = response.data.user[0].verified_access;
      userAdmin = response.data.user[0].admin;
      userAssociator = response.data.user[0].approver;
    }
  });

  return {
    user_id: userId,
    user_email: userEmail,
    user_first_name: userFirstName,
    user_last_name: userLastName,
    user_association: userAssociation,
    logged_in: loggedIn,
    user_verified_access: userAssociatorValidation,
    user_admin: userAdmin,
    user_associator: userAssociator,
  };
};

export default UserAuthorization;
