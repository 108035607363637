import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Link,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { dimensions } from "../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#fff", // background color white
  },
  card: {
    backgroundColor: "#fff", // card background color white
    padding: theme.spacing(4),
    borderRadius: "8px", // rounded corners for the card
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // subtle shadow for depth
    width: dimensions.width / 4.2, // increased width for better layout
    alignContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(2), // increased spacing for a clean look
    },
    backgroundColor: "#fff", // form background color white
  },
  submitButton: {
    marginTop: 1,
    width: "35%",
    color: "white",
    backgroundColor: "black",
    marginLeft: "auto", // Add this line to center the button
    marginRight: "auto", // Add this line to center the button
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    "&:disabled": {
      backgroundColor: "white",
      color: "black",
    },
  },
  link: {
    color: "#000", // link color gold
    "&:hover": {
      textDecoration: "underline", // underline on hover for links
    },
  },
  title: {
    color: "#000", // title color black
    textAlign: "center", // center the title
    marginBottom: theme.spacing(1), // margin below the title
  },
  input: {
    color: "#000", // input text color black
    "& .MuiInput-underline:before": {
      borderBottomColor: "#000", // underline color black when not focused
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#000", // underline color black on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000", // underline color black when focused
    },
    width: dimensions.width / 7, // increased width for better layout
    height: dimensions.height / 25,
    margin: theme.spacing(1), // increased spacing for a clean look
    marginLeft: "auto", // Add this line to center the button
    marginRight: "auto", // Add this line to center the button
  },
  visibilityIcon: {
    color: "#000", // visibility icon color black
  },
  signupStatusLabel: {
    color: "#000", // signup status label color black
    textAlign: "center", // center the signup status label
    marginTop: theme.spacing(2), // margin above the signup status label
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [signupStatus, setSignupStatus] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [approver, setApprover] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [approvers, setApprovers] = useState([]);
  const [reference, setReference] = useState("Guest");
  const [referenceName, setReferenceName] = useState("Guest");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const navigate = useNavigate();

  axios.defaults.withCredentials = true;

  useEffect(() => {
    // Fetch the list of approvers when the component mounts
    fetchApprovers();
  }, []);

  const fetchApprovers = async () => {
    var options = [];
    await axios.post("/get_all_approvers", {}).then((response) => {
      options.push({ value: "Guest", label: "Guest (No Approver)" });
      console.log(response.data);
      for (var i = 0; i < response.data.length; i++) {
        options.push({
          value: response.data[i].email,
          label: response.data[i].first_name + " " + response.data[i].last_name,
        });
      }
      setDropdownOptions(options);
    });
  };

  const setRef = async (option) => {
    console.log(option);
    setReference(option.value);
    setReferenceName(option.label);
  };

  const rules = [
    {
      exp: /[0-9]/,
      msg: "must contain at least one digit",
    },
    {
      exp: /[a-z]/,
      msg: "must contain at least one lowercase",
    },
    {
      exp: /[A-Z]/,
      msg: "must contain at least one uppercase",
    },
    {
      exp: /^.{6,20}$/,
      msg: "must be 6-20 characters long",
    },
  ];

  const submit = async (event) => {
    event.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      reference: reference,
      reference_name: referenceName,
      password: password,
    };
    console.log("sending to axios");
    var pass = true;
    var password_error = "";
    for (var i = 0; i < rules.length; i++) {
      var rule = rules[i];
      if (!rule.exp.test(password)) {
        pass = false;
        password_error = "Password " + rule.msg;
      }
    }

    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      passwordConfirmation === "" ||
      reference === ""
    ) {
      setSignupStatus("No Blank Fields");
    } else if (
      firstName.length > 50 ||
      lastName.length > 50 ||
      email.length > 150 ||
      password.length > 50 ||
      passwordConfirmation.length > 50 ||
      reference.length > 50
    ) {
      setSignupStatus("One of The Fields Has Too Many Characters");
    } else if (password !== passwordConfirmation) {
      setSignupStatus("Passwords Do Not Match");
    } else if (password.indexOf(" ") !== -1) {
      setSignupStatus("Password must contain no whitespaces");
    } else if (pass === false) {
      setSignupStatus(password_error);
    } else {
      var valid_email = false;
      const email_data = { email: email };
      await axios.post("/validate_email", email_data).then((response) => {
        console.log(response);
        if (response.data.message) {
          setSignupStatus(response.data.message);
          valid_email = response.data.valid;
          console.log("Response Data: ", response.data);
        } else {
          valid_email = response.data.valid;
          console.log("Response Data: ", response.data);
        }
      });
      if (valid_email) {
        console.log("valid email");
        const result = await axios
          .post("/create_new_user", data)
          .then((response) => {
            console.log(response);
          });
        console.log("redirecting");
        console.log(result);
        alert("A link has been sent to your email to confirm your account");
        window.location.href = "/";
      }
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardContent className={classes.form}>
          <Typography variant="h5" className={classes.title}>
            Sign Up
          </Typography>
          <form className={classes.form} onSubmit={submit}>
            <TextField
              placeholder="First Name"
              InputProps={{
                className: classes.input,
              }}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              placeholder="Last Name"
              InputProps={{
                className: classes.input,
              }}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              type="email"
              placeholder="Email"
              InputProps={{
                className: classes.input,
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              InputProps={{
                className: classes.input,
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    className={classes.visibilityIcon}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                ),
              }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Password"
              InputProps={{
                className: classes.input,
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    className={classes.visibilityIcon}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                ),
              }}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            <Select
              value={reference}
              onChange={(e) => {
                const selectedOption = dropdownOptions.find(
                  (option) => option.value === e.target.value,
                );
                setRef(selectedOption);
              }}
              className={classes.input}
            >
              {dropdownOptions.map((approver) => (
                <MenuItem value={approver.value}>{approver.label}</MenuItem>
              ))}
            </Select>
            <Typography className={classes.signupStatusLabel}>
              {signupStatus}
            </Typography>
            <Button
              type="submit"
              variant="contained"
              className={classes.submitButton}
            >
              Sign Up
            </Button>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate("/login")}
              className={classes.link}
            >
              Already have an account? Log In
            </Link>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
