import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import UserAuthorization from "./Utilities/user_auth";
import audiogeneLogo from "./Images/AudioGene_logo.png";
import iowaLogo from "./Images/iowa_logo.png";
import TokenService from "./Utilities/token_service";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  Typography,
  ListItemIcon,
  ListItemText,
  Backdrop,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LoginIcon from "@material-ui/icons/ExitToApp"; // Import the icon you want to use for login
import AccountBoxIcon from "@material-ui/icons/AccountBox"; // Import the icon you want to use for account info
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#000", // Black background
    border: "3px solid #FFD700", // Visible gold border
    flexDirection: "column",
    alignItems: "center",
  },
  toolbar: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1),
    backgroundColor: "#000", // Black background
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    backgroundColor: "#000", // Black background=
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginLeft: "auto", // Add this line to center the button
    marginRight: "auto", // Add this line to center the button
  },
  iowaLogo: {
    marginRight: theme.spacing(2),
    top: theme.spacing(1),
    height: "100px", // Adjust the size of your IOWA logo if needed
  },
  audiogeneLogo: {
    height: "80px", // Adjust the size of your AudioGene logo if needed
  },
  navLinksContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#000", // Black background
  },
  navLink: {
    color: "#FFD700", // Gold text
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: "white", // Gold text
    },
    "&.active": {
      textDecoration: "underline",
    },
  },
  authLinks: {
    display: "flex",
    backgroundColor: "#000", // Black background
    marginRight: theme.spacing(2),
  },
  authButton: {
    color: "#FFD700", // Gold text
    "&:hover": {
      backgroundColor: "rgba(255, 215, 0, 0.2)", // Slight gold background on hover
    },
  },
  navLinks: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#000", // Black background
    marginLeft: theme.spacing(8),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#000",
    opacity: 0.5,
  },
  menuPaper: {
    backgroundColor: "#fff", // White background
    color: "#000", // Black text
  },
}));

export default function Navbar({ loggedIn, setLoggedIn, user_info }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    navigate("/login");
    handleClose();
  };

  const handleSignup = () => {
    navigate("/signup");
    handleClose();
  };

  const handleAccount = () => {
    navigate("/my_account");
  };

  const signOut = async () => {
    const email = TokenService.getUserEmail();
    const refresh_token = TokenService.getRefreshToken();
    TokenService.removeUser();
    await axios.post("/sign_out", {
      email: email,
      refresh_token: refresh_token,
    });
    console.log("Signed out");
    setLoggedIn(false);
    // Redirect to home page
    navigate("/");
    handleClose();
  };

  let userVerifiedAccess = user_info.user_verified_access;
  if (userVerifiedAccess === undefined) {
    userVerifiedAccess = false;
  } else if (user_info.user_verified_access !== 1) {
    userVerifiedAccess = false;
  } else {
    userVerifiedAccess = true;
  }

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <div className={classes.logoContainer}>
          <img src={iowaLogo} alt="IOWA Logo" className={classes.iowaLogo} />
          <img
            src={audiogeneLogo}
            alt="AudioGene Logo"
            className={classes.audiogeneLogo}
          />
        </div>

        <Toolbar className={classes.toolbar}>
          <div className={classes.navLinks}>
            <NavLink
              exact
              to="/"
              className={classes.navLink}
              activeClassName="active"
            >
              Home
            </NavLink>
            <NavLink
              to="/audioprofiles"
              className={classes.navLink}
              activeClassName="active"
            >
              Audio Profiles
            </NavLink>
            <NavLink
              to="/audioprofile_surfaceviewer"
              className={classes.navLink}
              activeClassName="active"
            >
              Surface Viewer
            </NavLink>
            <NavLink
              to="/audiogene"
              className={classes.navLink}
              activeClassName="active"
            >
              Run AudioGene
            </NavLink>
            <NavLink
              to="/audioGene_translational_dashboard"
              className={classes.navLink}
              activeClassName="active"
            >
              AudioGene Translational Dashboard
            </NavLink>
            <NavLink
              to="/demo"
              className={classes.navLink}
              activeClassName="active"
            >
              Demos/Help
            </NavLink>
            <NavLink
              to="/contact_us"
              className={classes.navLink}
              activeClassName="active"
            >
              Contact Us
            </NavLink>
          </div>
          <div className={classes.authLinks}>
            {!loggedIn ? (
              <>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <LoginIcon />
                  <Typography variant="h6" color="inherit">
                    Sign Up/Login
                  </Typography>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                  classes={{ paper: classes.menuPaper }} // Apply custom styles
                >
                  <MenuItem onClick={handleLogin}>
                    <ListItemIcon>
                      <LoginIcon />
                    </ListItemIcon>
                    <ListItemText primary="Login" />
                  </MenuItem>
                  <MenuItem onClick={handleSignup}>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Up" />
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                  <Typography variant="h6" color="inherit">
                    Account
                  </Typography>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}
                  classes={{ paper: classes.menuPaper }} // Apply custom styles
                >
                  <MenuItem onClick={handleAccount}>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                  </MenuItem>
                  <MenuItem onClick={signOut}>
                    <ListItemIcon>
                      <LoginIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out" />
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={open}
        onClick={handleClose}
      />
    </>
  );
}
