import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link, Box } from "@material-ui/core";
import morlLogo from "./Images/morl_logo.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#000",
    border: "3px solid #FFD700",
    color: "#FFD700",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  link: {
    color: "#FFD700",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: "white",
    },
  },
  linkSection: {
    display: "flex",
    alignItems: "flex-end", // Align items to the bottom
    justifyContent: "center",
    backgroundColor: "#000",
    width: "100%",
    marginRight: theme.spacing(2),
  },
  linkText: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#FFD700",
    marginBottom: theme.spacing(2), // Push the text down to align with the logo's baseline
    marginRight: theme.spacing(4),
  },
  morlLogo: {
    height: "80px",
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2), // Align the logo bottom with the text baseline
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  morlContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
    paddingBottom: theme.spacing(2), // Push the container up to align with other texts
  },
  copyrightSection: {
    borderTop: "1px solid #FFD700",
    backgroundColor: "#000",
    width: "100%",
    textAlign: "center",
    paddingTop: theme.spacing(1),
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Box className={classes.linkSection}>
        <Typography className={classes.linkText}>
          <Link href="/citing_us" className={classes.link}>
            Citing Us
          </Link>
        </Typography>
        <Box className={classes.morlContainer}>
          <img src={morlLogo} alt="MORL Logo" className={classes.morlLogo} />
          <Typography
            variant="body1"
            className={classes.linkText}
            style={{ marginBottom: 0 }}
          >
            <Link
              href="https://morl.lab.uiowa.edu/"
              target="_blank"
              rel="noreferrer"
              className={classes.link}
            >
              Molecular Otolaryngology & Renal Research Laboratories (MORL)
            </Link>
          </Typography>
        </Box>
        <Typography className={classes.linkText}>
          <Link href="/about" className={classes.link}>
            About
          </Link>
        </Typography>
      </Box>
      <Box className={classes.copyrightSection}>
        <Typography variant="body1" className={classes.linkText}>
          © Copyright 2012-2024, Center for Bioinformatics and Computational
          Biology at The University of Iowa
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
