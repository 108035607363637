// export default AudioGene;
import React, { useEffect, useState } from "react";
import {
  Paper,
  Tooltip,
  IconButton,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TableComponent from "./tableComponent";
import SubmittedDataTable from "./SubmittedDataTable";
import ProcessAudiogramData from "./ProcessAudiogramData";
import { useAudioProfile } from "../contexts/AudioProfileContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    marginBottom: "10px",
  },
  tooltipText: {
    marginRight: theme.spacing(1), // Adjust the spacing as needed
    color: "black",
    fontSize: "1.15rem",
  },
  customTooltip: {
    fontSize: "1.15rem", // Adjust the font size as needed
  },
}));

const InfoTooltip = ({ info }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={<Typography className={classes.customTooltip}>{info}</Typography>}
      placement="top"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 0], // Adjust the position of the tooltip as needed
            },
          },
        ],
      }}
      interactive
    >
      <IconButton>
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );
};

const AudioGene = () => {
  const [predictAudioProfiles, setPredictAudioProfiles] = useState(false);
  const [predictAudioProfilesCounter, setPredictAudioProfilesCounter] =
    useState(0);
  const [audiogramPredicted, setAudiogramPredicted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { tableData } = useAudioProfile();

  const handlePrectionClick = () => {
    setPredictAudioProfiles(true); // Trigger new prediction
    setPredictAudioProfilesCounter(predictAudioProfilesCounter + 1);
    setAudiogramPredicted(false);
    setLoading(true);
  };

  useEffect(() => {
    if (audiogramPredicted) {
      setLoading(false);
    }
  }, [audiogramPredicted]);

  return (
    <Paper elevation={3} sx={{ p: 2, m: 2 }}>
      <Typography variant="h4" gutterBottom>
        AudioGene Analysis v4.0 - Multiple Audiogram Classification
        <InfoTooltip info="AudioGene v4.0 is a multi-instance support vector machine algorithm that considers all available audiograms for a given patient or family when making genotype predictions." />
      </Typography>
      <Typography paragraph>
        AudioGene v4.0 is a multi-instance support vector machine algorithm that
        simultaneously considers all available audiograms for a given patient or
        family when making genotype predictions. This allows for age-related
        progression to be implicitly incorporated by the algorithm.
      </Typography>
      <Typography variant="h4" gutterBottom>
        AudioGene Analysis v9.1 - Single Audiogram Classification
        <InfoTooltip info="AudioGene v9.1 is based on an ensemble of multiple machine learning algorithms that offer multiple predictions, resulting in a single genotype prediction." />
      </Typography>
      <Typography paragraph>
        AudioGene v9.1, developed in 2021 but released for use in 2022, is based
        on the idea of an <span className="bold-italic"> ensemble </span> of
        multiple machine learning algorithms that offer multiple predictions,
        which when taken together (a process similar to voting) result in a
        single genotype prediction (but reported on this website as the "top 3"
        predictions). As a single-instance classifier, Audiogene v9.1 makes
        predictions based on individual audiograms. As one consequence of this
        approach, the set of predictions made for{" "}
        <span className="bold-italic"> all </span> available audiograms (for one
        patient and/or a family of patients) may be more than 3 predicted genes.
        This raises the possibility for the patient, physician, or genetic
        counselor to incorporate knowledge of this set of predictions in the
        most appropriate way when attempting to arrive at a genetic diagnosis.
      </Typography>
      <Typography variant="h4" gutterBottom>
        File Upload
        <InfoTooltip info="Click 'Choose File' to upload your data for analysis." />
      </Typography>
      <Typography paragraph>
        To process data using the previous site's template, click "Choose File"
        and select your file to have the table update automatically.
        Furthermore, the option of downloading a template is available to enter
        data via Excel or utilize the input table below.
      </Typography>
      <Box>
        <TableComponent />
        {tableData && tableData.length > 0 && (
          <SubmittedDataTable handlePredictClick={handlePrectionClick} />
        )}
        {tableData && tableData.length > 0 && predictAudioProfiles && (
          <ProcessAudiogramData
            predictAudioProfilesCounter={predictAudioProfilesCounter}
            setAudiogramPredicted={setAudiogramPredicted}
            usingWalkthrough={false}
          />
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default AudioGene;
