import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import UserAuthorization from "../Utilities/user_auth";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000", // Black color
    },
    background: {
      default: "#fff", // White background
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#000", // Black text for buttons
          margin: "10px",
        },
      },
    },
  },
});

export default function MyAccount() {
  const [userInfo, setUserInfo] = useState({});
  const [workspaceRows, setWorkspaceRows] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [verifiedAccess, setVerifiedAccess] = useState(false);
  const [workspaceSort, setWorkspaceSort] = useState("Date");
  const userId = localStorage.getItem("user_id");
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");

  axios.defaults.withCredentials = true;

  useEffect(() => {
    (async () => {
      const user_info = await UserAuthorization();
      if (user_info.logged_in) {
        setUserInfo(user_info);
        setLoggedIn(user_info.logged_in);
        setVerifiedAccess(user_info.user_verified_access);
      } else {
        window.location.href = "/login";
      }
    })();
  }, []);

  const getUserWorkspaces = async () => {
    if (!loggedIn) return;
    try {
      const sortParam =
        workspaceSort === "Date" ? "workspaces.timestamp" : "workspaces.name";
      const response = await axios.post("/find_workspaces", {
        id: userId,
        sort: sortParam,
      });
      createWorkspaceRows(response.data);
    } catch (error) {
      console.error("Failed to fetch workspaces:", error);
    }
  };

  const createWorkspaceRows = (workspaces) => {
    const newWorkspaceRows = workspaces.map((workspace) => (
      <Grid container spacing={2} key={workspace.id} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Workspace Name: {workspace.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            Date Created: {new Date(workspace.timestamp).toLocaleDateString()}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>Number of Audiograms: {workspace.audiograms}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => loadWorkspace(workspace.id)}
          >
            Load Workspace
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => deleteWorkspace(workspace.id)}
            sx={{ marginLeft: 1 }}
          >
            Delete Workspace
          </Button>
        </Grid>
      </Grid>
    ));
    setWorkspaceRows(newWorkspaceRows);
  };

  const resetWorkspaceView = () => {
    setWorkspaceRows([]);
  };

  const setSort = (sortMethod) => {
    setWorkspaceSort(sortMethod);
    resetWorkspaceView();
  };

  const deleteWorkspace = async (e) => {
    const id = e.target.id;
    if (
      !window.confirm("Are you sure you would like to delete this workspace?")
    )
      return;
    try {
      const response = await axios.post("/delete_workspace", { id });
      getUserWorkspaces();
    } catch (error) {
      console.error("Failed to delete workspace:", error);
    }
  };

  const loadWorkspace = (e) => {
    const id = e.target.id;
    localStorage.setItem("workspace_id", id);
    const url = `${window.location.protocol}//${window.location.host}/audiogene`;
    window.open(url, "_blank");
  };

  const handleChangePasswordClick = async () => {
    try {
      // Assuming you have an endpoint to generate and send a token
      const response = await axios.post("/create_user_email_token", {
        email: userInfo.user_email,
      });
      setToken(response.data.token);
      setShowChangePassword(true);
    } catch (error) {
      console.error("Error generating token for password change:", error);
    }
  };

  const handleConfirmChangePassword = async () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    try {
      const response = await axios.post("/reset_password", {
        token,
        password,
        confirmPassword,
      });
      console.log("Password change response:", response.data);
      if (response.data.message !== "Password updated") {
        alert(response.data.message);
        return;
      } else {
        alert("Password changed successfully!");
        // Reset state and close the change password fields
        setShowChangePassword(false);
        setPassword("");
        setConfirmPassword("");
        setToken("");
      }
    } catch (error) {
      console.error("Error changing password:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, padding: 3 }}>
        {loggedIn && (
          <Card
            sx={{
              marginBottom: 3,
              boxShadow: "0 3px 5px 2px rgba(128, 128, 128, .3)",
            }}
          >
            <CardContent>
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: 2,
                }}
              >
                Account Information
              </Typography>
              <Typography variant="body1" component="p">
                Email: {userInfo.user_email}
              </Typography>
              <Typography variant="body1" component="p">
                Name: {userInfo.user_first_name} {userInfo.user_last_name}
              </Typography>
              <Typography variant="body1" component="p">
                Approver: {userInfo.user_association}
              </Typography>
              <Typography variant="body1" component="p">
                Approved: {verifiedAccess ? "Yes" : "No"}
              </Typography>
              <Button
                variant="contained"
                onClick={handleChangePasswordClick}
                sx={{
                  marginTop: 1,
                  width: "15%",
                  color: "white",
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                  },
                }}
              >
                Change Password
              </Button>
              {showChangePassword && (
                <Box
                  sx={{
                    marginTop: 2,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    label="New Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ marginRight: 1, marginBottom: 1, width: "15%" }}
                  />
                  <TextField
                    label="Confirm New Password"
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{ marginRight: 1, width: "15%" }}
                  />
                  <Button
                    variant="contained"
                    onClick={handleConfirmChangePassword}
                    sx={{
                      marginTop: 1,
                      width: "15%",
                      color: "white",
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                      },
                    }}
                  >
                    Confirm Change Password
                  </Button>
                </Box>
              )}
              {!!userInfo.user_admin && (
                <Typography variant="body1" component="p" sx={{ marginTop: 2 }}>
                  <a href="/my_account/view_accounts">
                    View Account Information
                  </a>
                </Typography>
              )}
            </CardContent>
          </Card>
        )}
        {verifiedAccess && (
          <Box>
            <Card
              sx={{
                marginBottom: 3,
                boxShadow: "0 3px 5px 2px rgba(128, 128, 128, .3)",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: 2,
                  }}
                >
                  My Workspaces
                </Typography>
                <Button
                  variant="contained"
                  id="view-workspace-button"
                  onClick={getUserWorkspaces}
                  sx={{
                    marginTop: 1,
                    width: "15%",
                    color: "white",
                    backgroundColor: "black",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "black",
                    },
                  }}
                >
                  View My Workspaces
                </Button>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ marginBottom: 2 }}
                >
                  Sort Workspaces:
                  <select
                    id="select"
                    className="dropdown-select"
                    onChange={(e) => setSort(e.target.value)}
                    style={{ marginLeft: "10px" }}
                  >
                    <option value="Date">Date Created</option>
                    <option value="Name">Name A-Z</option>
                  </select>
                </Typography>
                <Box className="user-account-rows">{workspaceRows}</Box>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
