// Feedback.js
import React, { useState } from "react";
import {
  Button,
  TextField,
  Card,
  CardContent,
  Box,
  Typography,
} from "@material-ui/core";
import axios from "axios";

const Feedback = ({ onBack }) => {
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [affiliation, setAffiliation] = useState("");

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = () => {
    // Send the feedback to the server
    axios
      .post("/api/feedback", { feedback, email, name, affiliation })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box display="flex" justifyContent="center" marginTop={2}>
      <Card
        style={{ width: "75%", boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)" }}
      >
        <CardContent>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <TextField
            label="Affiliation"
            value={affiliation}
            onChange={(e) => setAffiliation(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Typography variant="h6">Feedback:</Typography>
          <Typography variant="body1">
            Please tell us why you chose each feature, what you are using the
            tool for, and any insights you gained using the tool.
          </Typography>
          <TextField
            label="Feedback"
            multiline
            rows={4}
            value={feedback}
            onChange={handleFeedbackChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Button onClick={onBack}>Back</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Feedback;
