import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./navBar";
import Footer from "./footer";
import AppRouter from "./AppRouter";
import axios from "axios";
import UserAuthorization from "./Utilities/user_auth";
import TokenService from "./Utilities/token_service";
import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { AudioProfileProvider } from "./contexts/AudioProfileContext"; // Import AudioProfileProvider

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
  },
});

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user_info, setUser_info] = useState({});

  axios.defaults.withCredentials = true;

  useEffect(() => {
    const checkAuth = async () => {
      const user_info = await UserAuthorization();
      setUser_info(user_info);
      setLoggedIn(user_info.logged_in);
    };
    checkAuth();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AudioProfileProvider>
        {/* Wrap everything inside AudioProfileProvider */}
        <Router>
          <Navbar
            loggedIn={loggedIn}
            setLoggedIn={setLoggedIn}
            user_info={user_info}
          />
          <AppRouter loggedIn={loggedIn} />
          <Footer />
        </Router>
      </AudioProfileProvider>
    </ThemeProvider>
  );
}

export default App;
