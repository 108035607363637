import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Plot from "react-plotly.js";
import { dimensions } from "../constants";
import { useAudioProfile } from "../contexts/AudioProfileContext";
import styled from "@emotion/styled";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    color: "black",
    backgroundColor: "white",
    fontStyle: "italic", // Add this line to make the font italic
    "&:hover": {
      color: "gold",
      backgroundColor: "white",
    },
  },
  "&.MuiMenuItem-selected": {
    backgroundColor: "black",
    color: "white",
  },
  width: "100%",
}));

const EthnicityGeneScatter = ({ data, selectedGenes }) => {
  const { predictionData } = useAudioProfile();
  // Get unique genes for the dropdown
  const genes = [...new Set(data.map((item) => item.gene))]
    .filter((gene) => selectedGenes.includes(gene)) // Filter genes based on selectedGenes
    .sort()
    .map((gene) => ({
      value: gene,
      label: `${gene}`,
    }));

  // Determine the initial gene based on predictionData
  let initialGene = "";
  if (predictionData.audiogenev4 && predictionData.audiogenev4.length > 0) {
    initialGene = predictionData.audiogenev4[0].genes[0]; // First gene of the first ID in audiogenev4
  } else if (
    predictionData.audiogenev9 &&
    predictionData.audiogenev9.length > 0
  ) {
    initialGene = predictionData.audiogenev9[0].genes[0]; // First gene of the first ID in audiogenev9
  } else {
    initialGene = genes[0]; // Fallback to the first gene alphabetically if no predictionData
  }

  // Set the first gene as the initial selected value
  const [selectedGene, setSelectedGene] = useState(initialGene);
  // Add an "All Genes" option
  const allGenesOption = { value: "All Genes", label: "All Genes" };
  genes.unshift(allGenesOption); // Add to the beginning of the array

  // Adjusted data filtering based on selectedGene
  const filteredData =
    selectedGene === "All Genes"
      ? data
      : data.filter((item) => item.gene === selectedGene);

  // Count the prevalence of each ethnicity for the selected gene
  const ethnicityCounts = filteredData.reduce((acc, curr) => {
    const ethnicity = curr.ethnicity; // Corrected the property name here
    acc[ethnicity] = (acc[ethnicity] || 0) + 1;
    return acc;
  }, {});

  const pieData = [
    {
      labels: Object.keys(ethnicityCounts),
      values: Object.values(ethnicityCounts),
      type: "pie",
    },
  ];

  // Handle change event for the Select component
  const handleChange = (event) => {
    setSelectedGene(event.target.value);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        margin: "auto",
      }}
    >
      <FormControl
        style={{
          margin: 20,
          minWidth: "15%",
          alignContent: "center",
          textAlign: "left",
        }}
        variant="outlined"
      >
        <InputLabel id="gene-select-label">Gene</InputLabel>
        <Select
          labelId="gene-select-label"
          id="gene-select"
          value={selectedGene}
          label="Gene"
          onChange={handleChange}
          style={{ width: dimensions.width / 3, fontStyle: "italic" }}
        >
          {genes.map((gene) => (
            <StyledMenuItem
              key={gene.value}
              value={gene.value}
              style={{
                color: selectedGene === gene.value ? "darkgoldenrod" : "black",
                width: dimensions.width / 6,
                height: "40px",
              }}
            >
              {gene.label}
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedGene && (
        <Plot
          data={[...pieData]} // Spread operator to ensure it's an array of trace objects
          layout={{
            title: `Prevalence of Each Ethnicity for <i>${selectedGene}</i>`,
            height: dimensions.height / 1.8,
            width: dimensions.width / 2.2,
          }}
        />
      )}
    </div>
  );
};

export default EthnicityGeneScatter;
