import React, { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
  Box,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { dimensions } from "../constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
    backgroundColor: "#fff", // background color white
  },
  card: {
    backgroundColor: "white", // card background color white
    padding: theme.spacing(4),
    borderRadius: "8px", // rounded corners for the card
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)", // subtle shadow for depth
    width: dimensions.width / 4.2, // increased width for better layout
    alignContent: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(2), // increased spacing for a clean look
    },
    backgroundColor: "white", // form background color white
  },
  link: {
    color: "black", // link color gold
    "&:hover": {
      textDecoration: "underline", // underline on hover for links
    },
  },
  title: {
    color: "#000", // title color black
    textAlign: "center", // center the title
    marginBottom: theme.spacing(1), // margin below the title
  },
  input: {
    color: "#000", // input text color black
    "& .MuiInput-underline:before": {
      borderBottomColor: "#FFD700", // underline color gold when not focused
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#FFD700", // underline color gold on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#FFD700", // underline color gold when focused
    },
    width: dimensions.width / 7, // increased width for better layout
    height: dimensions.height / 25,
    margin: theme.spacing(1), // increased spacing for a clean look
  },
  visibilityIcon: {
    color: "black", // visibility icon color gold
  },
  loginStatusLabel: {
    color: "black", // login status label color gold
    textAlign: "center", // center the login status label
    marginTop: theme.spacing(2), // margin above the login status label
  },
  button: {
    marginTop: 1,
    width: "35%",
    color: "white",
    backgroundColor: "black",
    marginLeft: "auto", // Add this line to center the button
    marginRight: "auto", // Add this line to center the button
    "&:hover": {
      backgroundColor: "white",
      color: "black",
    },
    "&:disabled": {
      backgroundColor: "white",
      color: "black",
    },
  },
}));

export default function PasswordAccount() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState("");
  const navigate = useNavigate();

  // axios.defaults.withCredentials = true; // Uncomment if needed

  const checkEmail = async (event) => {
    event.preventDefault();
    const email_data = { email: email };
    console.log("sending data");
    await axios.post("/check_email", email_data).then((response) => {
      console.log("RESPONSE: ");
      console.log(response);
      if (response.data.message) {
        setEmailStatus(response.data.message);
        alert(response.data.message);
        console.log("wrong email");
      } else {
        setEmailStatus("");
        alert(
          "An email has been sent to you with a link to reset your password",
        );
        navigate("/");
      }
    });
  };

  return (
    <Box className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" className={classes.title}>
            Reset Password
          </Typography>
          <form className={classes.form} onSubmit={checkEmail}>
            <TextField
              type="email"
              placeholder="Email"
              InputProps={{
                className: classes.input,
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Typography className={classes.loginStatusLabel}>
              {emailStatus}
            </Typography>
            <Button
              type="submit"
              variant="contained"
              className={classes.button}
            >
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
}
